import React from "react";
import PropTypes from "prop-types";
import { StarsModalContext } from "../providers";

const buttonClass = (type, className) => {
  let buttonClass;

  if (type === "primary") buttonClass = "btn-primary";
  if (type === "secondary") buttonClass = "btn-secondary";
  if (type === "text") buttonClass = "btn-text";
  if (className) buttonClass = `${buttonClass} ${className}`;

  return buttonClass;
};

const SimpleButton = ({ settings, className }) => {
  const { id, label, link, target, type, follow_link } = settings;

  return (
    <a
      href={link}
      className={buttonClass(type, className)}
      id={id ? id : ""}
      target={target}
      rel={follow_link === "no-follow" ? "nofollow" : ""}
    >
      {label}
    </a>
  );
};

const ButtonWithModal = ({ settings, modalParams, className }) => {
  const { id, label, link, target, type, follow_link } = settings;
  const openModal = React.useContext(StarsModalContext);

  return (
    <a
      href={link}
      className={buttonClass(type, className)}
      id={id ? id : ""}
      target={target}
      rel={follow_link === "no-follow" ? "nofollow" : ""}
      onClick={(e) => {
        e.preventDefault();
        openModal(modalParams);
      }}
    >
      {label}
    </a>
  );
};

const REGEX = /^#popup-(?<slug>.*)$/;

const Button = ({ settings, className }) => {
  const { link } = settings;

  if (link === "#request-information-modal") {
    return (
      <ButtonWithModal
        settings={settings}
        className={className}
        modalParams={{ modal: "full" }}
      />
    );
  }

  if ((link || "").match(REGEX)) {
    const result = link.match(REGEX);

    return (
      <ButtonWithModal
        settings={settings}
        className={className}
        modalParams={{ modal: "simple", slug: result.groups.slug }}
      />
    );
  }

  return <SimpleButton settings={settings} className={className} />;
};

Button.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default Button;
