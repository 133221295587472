import React from "react";
import { Container, LinkSettings, EmbeddedVideo } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Link from "../frontend/Link";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const Content = ({ image, title, description, link }) => (
  <div className="content-wrapper">
    <div className="avatar">
      <Img src={image} variant="thumb" loading="lazy" alt={title} />
    </div>

    <div className="content">
      <h4 className="title">{title}</h4>
      <HtmlLinksDecoration className="description" text={description} />

      {link.label && (
        <div className="button-wrapper">
          <Link settings={link} />
        </div>
      )}
    </div>
  </div>
);

const PersonSpotlightWidget = ({
  image,
  title,
  description,
  link,
  videoURL,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="person-spotlight">
      <div className="container">
        {videoURL && (
          <div className="row">
            <div className="col-6">
              <Content
                image={image}
                title={title}
                description={description}
                link={link}
              />
            </div>

            <div className="col-6">
              <div className="video-wrapper">
                <EmbeddedVideo videoUrl={videoURL} />
              </div>
            </div>
          </div>
        )}

        {!videoURL && (
          <div className="row">
            <div className="shift-2">
              <Content
                image={image}
                title={title}
                description={description}
                link={link}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  </Container>
);

PersonSpotlightWidget.title = "Person spotlight";
PersonSpotlightWidget.category = "Media";
PersonSpotlightWidget.primaryField = "title";

PersonSpotlightWidget.defaults = {
  image: "https://placehold.it/200x200",
  title: "Title",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum dolores iusto itaque. Obcaecati praesentium possimus nesciunt neque debitis ipsam ad maiores dolorem? </p>",
  link: LinkSettings.defaults,
  videoURL: "https://www.youtube.com/watch?v=7Glc2mHLPRM",
  containerSettings: {},
};

PersonSpotlightWidget.adminFields = {
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  link: { type: "custom", component: LinkSettings },
  videoURL: { type: "text" },
};

export default PersonSpotlightWidget;
