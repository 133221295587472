import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const TextSidebarWidget = ({ text, sidebar, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="text-sidebar">
      <div className="container">
        <div className="row">
          <HtmlLinksDecoration className="col-8" text={text} />
          <div className="col-4">
            <HtmlLinksDecoration className="sidebar" text={sidebar} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

TextSidebarWidget.title = "Text + Sidebar";
TextSidebarWidget.category = "General";
TextSidebarWidget.primaryField = "text";

TextSidebarWidget.defaults = {
  text: "<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam nulla eos quam cumque, rem dolor harum obcaecati odio dolores et assumenda ipsa veritatis? Quos, soluta nesciunt. Nobis vitae fugit expedita.</p>",
  sidebar:
    "<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam nulla eos quam cumque, rem dolor harum obcaecati odio dolores et assumenda ipsa veritatis? Quos, soluta nesciunt. Nobis vitae fugit expedita.</p>",
  containerSettings: {},
};

TextSidebarWidget.adminFields = {
  text: { type: "custom", component: RichText },
  sidebar: { type: "custom", component: RichText },
};

export default TextSidebarWidget;
