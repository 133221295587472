import React, { useState } from "react";

export default ({ settings }) => {
  const [open, setOpen] = useState(true);

  return settings.global_notification_active && open ? (
    <div className="global-notice">
      <div className="spacing-both-sm">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{
              __html: settings.global_notification_content,
            }}
          />

          <i className="close-icon" onClick={() => setOpen(false)} />
        </div>
      </div>
    </div>
  ) : null;
};
