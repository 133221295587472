import React, { useState } from "react";
import { RichText } from "@raketa-cms/raketa-rte";

const RichTextEditor = ({ label = "Text", value, name, placeholder }) => {
  const [editorValue, setEditorValue] = useState(value);
  console.log(editorValue);
  return (
    <div className="form-group">
      <label className="control-label">{label}</label>
      <RichText
        value={editorValue}
        placeholder={placeholder}
        onChange={(newValue) => setEditorValue(newValue)}
      />

      <input type="hidden" name={name} value={editorValue} />
    </div>
  );
};

export default RichTextEditor;
