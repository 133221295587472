import React from "react";
import { Container, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const getBackground = (theme, opacity) => {
  if (theme === "brand") return `rgba(45, 92, 148, ${opacity})`;
  return `rgba(0, 0, 0, ${opacity})`;
};

const SimpleLeadImageWidget = ({
  height,
  overlay,
  overlay_theme,
  background,
  background_mobile,
  title,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="simple-lead-image" style={{ height }}>
      <div
        className="mobile-background"
        style={{
          backgroundImage: background_mobile
            ? `url("${imageSrc(background_mobile, "image")}")`
            : `url("${imageSrc(background, "image")}")`,
        }}
      >
        <div
          className="desktop-background"
          style={{
            backgroundImage: `url("${imageSrc(background, "lead")}")`,
          }}
        >
          <div className="container">
            <div
              className="content"
              style={{ backgroundColor: getBackground(overlay_theme, overlay) }}
            >
              <h1 className="title">{title}</h1>

              {description !== "<p><br></p>" && (
                <HtmlLinksDecoration
                  className="description"
                  text={description}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

SimpleLeadImageWidget.title = "Lead image (Simple)";
SimpleLeadImageWidget.category = "Media";
SimpleLeadImageWidget.primaryField = "title";

SimpleLeadImageWidget.defaults = {
  height: "400px",
  overlay_theme: "black",
  overlay: "0.5",
  background: "https://placehold.it/1920x1080",
  title: "Title",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi animi magni commodi recusandae modi aliquam expedita. Reiciendis nulla eos cupiditate ut, consequuntur, debitis exercitationem, porro culpa unde animi quod tenetur!</p>",
  containerSettings: {},
};

SimpleLeadImageWidget.adminFields = {
  height: { type: "text" },
  overlay: {
    type: "select",
    options: [
      ["0", "None"],
      ["0.1", "10%"],
      ["0.2", "20%"],
      ["0.3", "30%"],
      ["0.4", "40%"],
      ["0.5", "50%"],
      ["0.6", "60%"],
      ["0.7", "70%"],
      ["0.8", "80%"],
      ["0.9", "90%"],
      ["1", "100%"],
    ],
  },
  overlay_theme: {
    type: "select",
    options: [
      ["black", "Black"],
      ["brand", "Brand"],
    ],
  },
  background: { type: "custom", component: ImagePicker },
  background_mobile: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
};

export default SimpleLeadImageWidget;
