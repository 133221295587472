import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import OpenHouseForm from "../frontend/Forms/OpenHouseForm";
import { AuthTokenContext } from "../providers";
import AimsPrograms from "../frontend/Forms/AimsPrograms";
import AimsEvents from "../frontend/Forms/AimsEvents";
import AimsProgramsInput from "../inputs/AimsProgramsInput";
import AimsEventsInput from "../inputs/AimsEventsInput";

const OpenHouseWidget = ({ list = [], events = [], containerSettings }) => {
  const token = React.useContext(AuthTokenContext);
  const programs = AimsPrograms.selectPrograms(list);
  const pickedEvents = AimsEvents.selectEvents(events);

  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-4">
            <OpenHouseForm
              token={token}
              programs={programs}
              events={pickedEvents}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

OpenHouseWidget.title = "Open House Form";
OpenHouseWidget.category = "Forms";

OpenHouseWidget.defaults = {
  containerSettings: {},
  list: AimsProgramsInput.defaults,
};

OpenHouseWidget.adminFields = {
  list: { type: "custom", component: AimsProgramsInput },
  events: { type: "custom", component: AimsEventsInput },
};

export default OpenHouseWidget;
