import React from "react";
import {
  Container,
  List,
  SelectMenu,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import Button from "../frontend/Button";

const ButtonsWidget = ({ align, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="buttons">
      <div className="container">
        <div className={align}>
          {list.map((item) => (
            <Button key={item.id} settings={item.button} />
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ButtonsWidget.title = "Buttons";
ButtonsWidget.category = "General";

ButtonsWidget.defaults = {
  align: "text-center",
  list: [
    { id: 1, button: ButtonSettings.defaults },
    { id: 2, button: ButtonSettings.defaults },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ButtonSettings
      label="button"
      onChange={(value) => onChangeItem("button", value)}
      value={settings.button}
    />
  </div>
);

ButtonsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Align"
      options={[
        ["text-center", "Center"],
        ["text-left", "Left"],
      ]}
      value={settings.align}
      onChange={(value) => onChange("align", value)}
    />

    <List
      label="buttons"
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{ button: ButtonSettings.defaults }}
      primaryField="button.label"
      onChangeList={onChange}
    />
  </div>
);

export default ButtonsWidget;
