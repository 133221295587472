import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import TranscriptRequestForm from "../frontend/Forms/TranscriptRequestForm";

const TranscriptRequestWidget = ({ containerSettings }) => {
  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <TranscriptRequestForm />
          </div>
        </div>
      </div>
    </Container>
  );
};

TranscriptRequestWidget.title = "Transcript Request Form";
TranscriptRequestWidget.category = "Forms";

TranscriptRequestWidget.defaults = {
  containerSettings: {},
};

export default TranscriptRequestWidget;
