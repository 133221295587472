const DeliverTo = [
  { id: 1, value: "Self", label: "Self" },
  {
    id: 2,
    value: "Employer",
    label: "Employer",
  },
  {
    id: 3,
    value: "School",
    label: "School",
  },
];

export default DeliverTo;
