import React from "react";
import { SelectMenu, List } from "@raketa-cms/raketa-cms";
import AimsPrograms from "../frontend/Forms/AimsPrograms";

const ListItem = ({ settings, onChangeItem }) => (
  <>
    <SelectMenu
      label="Program"
      options={AimsPrograms.map((prog) => [prog.value, prog.label])}
      value={settings.program}
      onChange={(value) => onChangeItem("program", value)}
    />
  </>
);

function AimsProgramsInput({ value, onChange }) {
  return (
    <List
      label="Programs"
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={value}
      template={{
        program: "",
      }}
      primaryField="program"
      onChangeList={(_key, list) => onChange(list)}
    />
  );
}

AimsProgramsInput.defaults = AimsPrograms.defaults;

export default AimsProgramsInput;
