import React from "react";
import { TextInput, List } from "@raketa-cms/raketa-cms";

const ListItem = ({ settings, onChangeItem }) => (
  <>
    <TextInput
      label="Title"
      value={settings.title}
      placeholder=""
      onChange={(value) => onChangeItem("title", value)}
    />

    <TextInput
      label="URL"
      value={settings.url}
      placeholder=""
      onChange={(value) => onChangeItem("url", value)}
    />
  </>
);

function SessionTypeInput({ value, onChange }) {
  return (
    <List
      label="Which type of session did you register for?"
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={value}
      template={{
        title: "",
        url: "",
      }}
      primaryField="title"
      onChangeList={(_key, list) => onChange(list)}
    />
  );
}

SessionTypeInput.defaults = [];

export default SessionTypeInput;
