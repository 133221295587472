const AimsEvents = [
  {
    id: 1,
    value: "OPENHOUSE",
    label: "Open House",
  },
  {
    id: 2,
    value: "VIRTOPNHSE",
    label: "Virtual Open House",
  },
];

AimsEvents.selectEvents = (list) => {
  return list.map((item) => AimsEvents.find((ae) => ae.value === item.event));
};

AimsEvents.defaults = AimsEvents.map((event, idx) => ({
  id: idx,
  event: event.value,
}));

export default AimsEvents;
