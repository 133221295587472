import React from "react";

const extractWebpPath = (path) => {
  const arrayPath = path.split(".");
  arrayPath[arrayPath.length - 1] = "webp";

  return arrayPath.join(".");
};

const addSizes = (src, variant, props) => {
  if (
    src.sizes &&
    src.sizes[variant] &&
    src.sizes[variant].width &&
    src.sizes[variant].height
  ) {
    const width = src.sizes[variant].width;
    const height = src.sizes[variant].height;

    return Object.assign({ width, height }, props);
  }

  return props;
};

const escapeURI = (uri) => encodeURI(uri);

const Image = ({
  src,
  variant,
  className,
  onLoad,
  alt = null,
  title = null,
  ...props
}) => {
  if (!src) {
    console.warn(
      `WARNING: Image with src null or undefined will not be rendered: ${variant} ${
        className || ""
      }`
    );
    return null;
  }

  const enchancedProps =
    typeof src === "object" ? addSizes(src, variant, props) : props;

  const imgAlt = alt !== null ? alt : src.alt;
  const imgTitle = title !== null ? title : "";

  if (typeof src === "object" && !("name" in src))
    console.warn(`WARNING: Image with empty {}: ${variant} ${className || ""}`);

  if (
    typeof src === "object" &&
    "name" in src &&
    (src.name.includes(".svg") || src.name.includes(".gif"))
  )
    return (
      <img
        src={src.urls.original}
        alt={imgAlt !== null ? imgAlt : src.name}
        className={className}
        onLoad={onLoad}
        title={imgTitle}
        {...enchancedProps}
      />
    );
  if (typeof src === "object" && "name" in src)
    return (
      <picture>
        <source
          srcSet={escapeURI(extractWebpPath(src.urls[variant]))}
          type="image/webp"
        />
        <source srcSet={escapeURI(src.urls[variant])} type={src.mime_type} />
        <img
          src={src.urls[variant]}
          alt={imgAlt !== null ? imgAlt : src.name}
          className={className}
          onLoad={onLoad}
          title={imgTitle}
          {...enchancedProps}
        />
      </picture>
    );
  if (typeof src === "string")
    return (
      <img
        src={src}
        alt={src}
        className={className}
        onLoad={onLoad}
        title={imgTitle}
        {...enchancedProps}
      />
    );

  return null;
};

export default Image;
