import React from "react";
import WIDGETS from "../admin/menu-widgets";
import Button from "../frontend/Button";
import GlobalNotice from "./GlobalNotice";

const MenuRenderer = ({ widgets, library }) => (
  <>
    {widgets.map((widget) =>
      React.createElement(library[widget.component], {
        key: widget.id,
        ...widget.settings,
      })
    )}
  </>
);

const NavigationItem = ({ item }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className={`navigation-item ${open ? "node-open" : ""} ${
        item.rows.length > 0 && "has-children"
      }`}
    >
      <div className="main-link-wrapper">
        <a href={item.url} className="main-link has-subnav">
          {item.label}
        </a>

        <button
          type="button"
          className={`sub-trigger ${open ? "open" : ""}`}
          onClick={() => setOpen(!open)}
        >
          {item.label}
        </button>
      </div>

      {item.rows.length > 0 && (
        <div className="navigation-panel">
          <div className="navigation-panel-container">
            {item.rows.map((row) => (
              <div key={row.id} className="navigation-row">
                {row.columns.length > 0 && (
                  <>
                    {row.columns.map((column) => (
                      <div key={column.id} className="navigation-column">
                        <MenuRenderer
                          library={WIDGETS}
                          widgets={column.widgets}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ({ showGlobalNotice, context: { header, settings } }) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const searchRef = React.useRef(null);
  const [query, setQuery] = React.useState("");
  const [isShrunk, setShrunk] = React.useState(false);

  React.useEffect(() => {
    if (search) searchRef.current.focus();
  }, [search]);

  React.useEffect(() => {
    const handler = () => {
      setShrunk(window.scrollY > 10);
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <div
      className={`header ${open ? "is-open" : ""} ${
        isShrunk ? "is-shrunk" : ""
      } ${search ? "is-search" : ""}`}
    >
      {showGlobalNotice && <GlobalNotice settings={settings} />}

      <div className="supplementary">
        <div className="mini-header">
          <form action="/search" className="search-form">
            <input
              ref={searchRef}
              type="search"
              name="q"
              value={query}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button type="submit">Search</button>
          </form>

          <nav className="mini-navigation">
            <a href="tel:+19082220002" className="icon phone">
              (908) 222-0002
            </a>
            <a
              href="https://g.page/AIMS-Piscataway"
              target="_blank"
              className="icon directions"
            >
              Directions
            </a>
            <a href="/contactus" className="icon contacts">
              Contact
            </a>
          </nav>
        </div>
      </div>

      <div className="main">
        <div className="logo-wrapper">
          <a href="/" className="aims-logo">
            <picture>
              <source
                srcSet="/logos/aims-logo-small-1.avif 1x, /logos/aims-logo-small-1@2x.avif 2x"
                type="image/avif"
              />

              <source
                srcSet="/logos/aims-logo-small-1.png 1x, /logos/aims-logo-small-1@2x.png 2x"
                type="image/png"
              />

              <img
                src="/logos/aims-logo-small-1.png"
                alt="AIMS Education"
                className="small-logo"
              />
            </picture>

            <picture>
              <source
                srcSet="/logos/aims-logo-large-1.avif 1x, /logos/aims-logo-large-1@2x.avif 2x"
                type="image/avif"
              />

              <source
                srcSet="/logos/aims-logo-large-1.png 1x, /logos/aims-logo-large-1@2x.png 2x"
                type="image/png"
              />

              <img
                src="/logos/aims-logo-large-1.png"
                alt="AIMS Education"
                className="large-logo"
              />
            </picture>
          </a>
        </div>

        <nav className="main-navigation">
          {header.items.map((item) => (
            <NavigationItem key={item.id} item={item} />
          ))}
        </nav>

        <div className="mobile-buttons-wrapper">
          <div className="mobile-buttons">
            <button
              type="button"
              className="icon search"
              onClick={() => setSearch(!search)}
            >
              Open search
            </button>
          </div>

          <button
            type="button"
            className="menu-trigger"
            onClick={() => setOpen(!open)}
          >
            Menu
          </button>
        </div>

        <div className="cta-wrapper">
          <Button settings={settings.header_cta} />
        </div>
      </div>
    </div>
  );
};
