import React, { useState } from "react";
import {
  LinkSettings,
  ListInput,
  TextInput,
  RaketaUIProvider,
} from "@raketa-cms/raketa-cms";

const Program = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      value={settings.link}
      placeholder=""
      onChange={(value) => onChangeItem("link", value)}
    />

    <TextInput
      label="Date"
      value={settings.date}
      placeholder=""
      onChange={(value) => onChangeItem("date", value)}
    />
  </div>
);

const StickyMenuEditor = ({ value, name }) => {
  const [programs, setPrograms] = useState(value.programs || []);

  return (
    <RaketaUIProvider>
      <div className="field-pannel form-group">
        <label className="control-label">Programs</label>
        <ListInput
          template={{ link: {}, date: "" }}
          listItem={(settings, onChangeItem) => (
            <Program settings={settings} onChangeItem={onChangeItem} />
          )}
          onChangeList={(_, items) => setPrograms(items)}
          items={programs}
        />

        <input
          type="hidden"
          name={name}
          value={JSON.stringify({
            programs: programs,
          })}
        />
      </div>
    </RaketaUIProvider>
  );
};

export default StickyMenuEditor;
