import React, { useState } from "react";
import { ButtonSettings, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const ButtonEditor = ({ value, name, label, hint }) => {
  const [button, setButton] = useState(
    value || { id: "", link: "", label: "", type: "", target: "", rel: "" }
  );

  return (
    <RaketaUIProvider>
      <div className="field-pannel form-group">
        <ButtonSettings
          label={label}
          onChange={(value) => setButton(value)}
          value={button}
        />

        {hint && (
          <div
            className="help-block"
            dangerouslySetInnerHTML={{ __html: hint }}
          />
        )}

        <input type="hidden" name={name} value={JSON.stringify(button)} />
      </div>
    </RaketaUIProvider>
  );
};

export default ButtonEditor;
