import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const Text = ({ title, description, button_1, button_2 }) => (
  <div className="text-column">
    <div className="text-column-inner">
      {title && <h3 className="title">{title}</h3>}

      {description !== "<p><br></p>" && (
        <HtmlLinksDecoration className="description" text={description} />
      )}

      {(button_1.label || button_2.label) && (
        <div className="button-wrapper">
          {button_1.label && <Button settings={button_1} />}

          {button_2.label && <Button settings={button_2} />}
        </div>
      )}
    </div>
  </div>
);

const Slider = ({ list }) => (
  <div className="slider-column">
    <Swiper
      rebuildOnUpdate
      pagination={{
        el: ".swiper-pagination",
        clickable: true,
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      slidesPerView={1}
      spaceBetween={0}
      paginationClickable
    >
      {list.map((slide) => (
        <a key={slide.id} className="slide">
          <Img
            width="576"
            height="382"
            src={slide.image}
            variant="fixed_image"
            loading="lazy"
            alt={slide.title}
          />
        </a>
      ))}
    </Swiper>
  </div>
);

const SpotlightSliderWidget = ({ variant, containerSettings, ...props }) => (
  <Container settings={containerSettings}>
    <div className={`spotlight-slider ${variant}`}>
      <div className="container">
        <div className="row">
          <div className="col-6">
            {variant === "right" ? <Text {...props} /> : <Slider {...props} />}
          </div>

          <div className="col-6">
            {variant === "right" ? <Slider {...props} /> : <Text {...props} />}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

SpotlightSliderWidget.title = "Spotlight slider";
SpotlightSliderWidget.category = "Media";

SpotlightSliderWidget.defaults = {
  variant: "right",
  title: "Title",
  description:
    "<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, eum suscipit quam autem aspernatur reiciendis necessitatibus est, dolore et recusandae quis atque ipsa obcaecati mollitia. Quae iste ipsa esse earum?</p>",
  button_1: ButtonSettings.defaults,
  button_2: ButtonSettings.defaults,
  list: [
    { id: 1, image: "http://placehold.it/800x600" },
    { id: 2, image: "http://placehold.it/800x600" },
    { id: 3, image: "http://placehold.it/800x600" },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

SpotlightSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["right", "Spotlight on right"],
        ["left", "Spotlight on left"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <TextInput
      label="Title"
      value={settings.title}
      onChange={(value) => onChange("title", value)}
    />

    <RichText
      label="Description"
      value={settings.description}
      onChange={(value) => onChange("description", value)}
    />

    <ButtonSettings
      label="Button 1"
      value={settings.button_1}
      onChange={(value) => onChange("button_1", value)}
    />

    <ButtonSettings
      label="Button 2"
      value={settings.button_2}
      onChange={(value) => onChange("button_2", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{ image: "http://placehold.it/800x600" }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default SpotlightSliderWidget;
