import React from "react";
import Swiper from "react-id-swiper";
import Link from "../frontend/Link";
import { StarsModalContext } from "../providers";

const shouldStick = (el) =>
  el.getBoundingClientRect().top >= window.innerHeight;

const StickyFooter = ({ settings }) => {
  const [sticky, setSticky] = React.useState(true);
  const {
    sticky_menu: { programs },
  } = settings;

  const openModal = React.useContext(StarsModalContext);

  const onScroll = () => {
    const footer = document.querySelector(".sticky-ghost");
    setSticky(shouldStick(footer));
  };

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div className="sticky-ghost"></div>

      <div className={`sticky-footer ${sticky ? "sticky" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-8">
              <Swiper
                rebuildOnUpdate
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                slidesPerView={1}
                spaceBetween={0}
                paginationClickable={true}
              >
                {programs.map((slide) => (
                  <div key={slide.id} className="slide">
                    <div className="slim-program">
                      <div className="title-wrapper">
                        <div className="program-label">Program:</div>
                        {slide.link.link && (
                          <Link settings={slide.link}>
                            <h4 className="title">{slide.link.label}</h4>
                          </Link>
                        )}
                        {!slide.link.link && (
                          <h4 className="title">{slide.link.label}</h4>
                        )}
                      </div>

                      <div className="title-wrapper">
                        <div className="program-label">Session start:</div>
                        <h4 className="title">{slide.date}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Swiper>
            </div>

            <div className="col-4">
              <div className="cta-buttons">
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => openModal({ modal: "full" })}
                >
                  Request Info
                </button>
              </div>

              <div className="mobile-buttons">
                <div className="icons-wrapper">
                  <button
                    type="button"
                    className="icon information"
                    onClick={() => openModal({ modal: "full" })}
                  >
                    Request information
                  </button>

                  <a href="tel:+19082220002" className="icon call">
                    Call us
                  </a>
                </div>

                <a
                  href="https://aimseducation.as.me/schedule.php"
                  className="btn-primary sm"
                  target="_blank"
                >
                  Campus visit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StickyFooter;
