import React from "react";
import { Input, FormGroup, Label } from "@raketa-cms/raketa-mir";

const extractWebpPath = (path) => {
  const arrayPath = path.split(".");
  arrayPath[arrayPath.length - 1] = "webp";

  return arrayPath.join(".");
};

const Image = ({ image }) => (
  <picture>
    <source srcSet={extractWebpPath(image)} type="image/webp" />
    <source srcSet={image} />
    <img src={image} loading="lazy" />
  </picture>
);

Image.defaults = {
  image: "https://placeholder.raketa.cloud/images/1920x1080",
};

Image.admin = ({ settings, onChange }) => {
  return (
    <>
      <FormGroup>
        <Label htmlFor="image">Image</Label>

        <Input
          name="image"
          type="text"
          value={settings.image}
          onChange={(e) => onChange("image", e.target.value)}
        />
      </FormGroup>
    </>
  );
};

export default Image;
