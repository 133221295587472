import React from "react";

const HostContext = React.createContext();

const HostProvider = ({ children, url }) => (
  <HostContext.Provider value={url}>{children}</HostContext.Provider>
);

const HostConsumer = HostContext.Consumer;

export { HostProvider, HostConsumer };
