import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const ArticleHeaderSimpleWidget = ({
  tag,
  title,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="article-header-simple">
      <div className="container">
        <span className="tag">{tag}</span>

        <h1 className="title">{title}</h1>

        {description && description !== "<p><br></p>" && (
          <HtmlLinksDecoration className="description" text={description} />
        )}
      </div>
    </div>
  </Container>
);

ArticleHeaderSimpleWidget.title = "Article Header";
ArticleHeaderSimpleWidget.category = "General";
ArticleHeaderSimpleWidget.primaryField = "title";

ArticleHeaderSimpleWidget.defaults = {
  tag: "News",
  title: "Article Title",
  description: "<p>January 19th, 2020</p>",
  containerSettings: {},
};

ArticleHeaderSimpleWidget.adminFields = {
  tag: { type: "text" },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
};

export default ArticleHeaderSimpleWidget;
