import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const ProfileWidget = ({ text, avatar, sidebar, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="profile">
      <div className="container">
        <div className="row">
          <div className="col-8">
            <HtmlLinksDecoration text={text} />
          </div>

          <div className="col-4">
            <Img
              src={avatar}
              variant="square"
              className="avatar"
              loading="lazy"
            />
            <HtmlLinksDecoration text={sidebar} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

ProfileWidget.title = "Profile";
ProfileWidget.category = "General";
ProfileWidget.primaryField = "text";

ProfileWidget.defaults = {
  text: "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni libero iure, corporis reprehenderit pariatur tempore itaque. Eveniet fugiat, qui eos quas illo similique officia itaque cum, pariatur tenetur deleniti aliquam.</p>",
  avatar: "https://placehold.it/540x540",
  sidebar:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni libero iure.</p>",
  containerSettings: {},
};

ProfileWidget.adminFields = {
  text: { type: "custom", component: RichText },
  avatar: { type: "custom", component: ImagePicker },
  sidebar: { type: "custom", component: RichText },
};

export default ProfileWidget;
