import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  TextInput,
  TextArea,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Button from "../frontend/Button";
import Link from "../frontend/Link";
import Img from "../frontend/Image";

const Item = ({ title, image, description, button }) => (
  <div className="article">
    {button.link && (
      <Link settings={{ link: button.link, target: button.target }}>
        <Img
          src={image}
          variant="card_horizontal"
          className="image"
          loading="lazy"
        />

        <h4 className="title">{title}</h4>
      </Link>
    )}

    {!button.link && (
      <Img
        src={image}
        variant="card_horizontal"
        className="image"
        loading="lazy"
        alt={button.label}
      />
    )}

    {!button.link && <h4 className="title">{title}</h4>}

    {description && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}

    {button.link && (
      <div className="button-wrapper">
        <Button settings={button} />
      </div>
    )}
  </div>
);

const ArticleSliderWidget = ({ list, containerSettings }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [currentIndex, updateCurrentIndex] = React.useState(0);

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  React.useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => updateCurrentIndex(swiper.realIndex));
    }

    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", () => updateCurrentIndex(swiper.realIndex));
      }
    };
  }, [swiper]);

  return (
    <Container settings={containerSettings}>
      <div className="article-slider">
        <div className="container">
          <button
            className={`article-slider-prev ${
              currentIndex === 0 && "inactive"
            }`}
            onClick={goPrev}
          >
            Prev
          </button>
          <button
            className={`article-slider-next ${
              currentIndex === list.length - 1 && "inactive"
            }`}
            onClick={goNext}
          >
            Next
          </button>

          <Swiper
            // rebuildOnUpdate
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            spaceBetween={16}
            paginationClickable={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              769: {
                slidesPerView: 4,
              },
            }}
            getSwiper={updateSwiper}
          >
            {list.map((slide) => (
              <div key={slide.id} className="slide">
                <Item {...slide} />
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

ArticleSliderWidget.title = "Article slider";
ArticleSliderWidget.category = "Media";

ArticleSliderWidget.defaults = {
  list: [
    {
      id: 1,
      image: "http://placehold.it/400x300",
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      button: ButtonSettings.defaults,
    },
    {
      id: 2,
      image: "http://placehold.it/400x300",
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      button: ButtonSettings.defaults,
    },
    {
      id: 3,
      image: "http://placehold.it/400x300",
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      button: ButtonSettings.defaults,
    },
    {
      id: 4,
      image: "http://placehold.it/400x300",
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      button: ButtonSettings.defaults,
    },
    {
      id: 5,
      image: "http://placehold.it/400x300",
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      button: ButtonSettings.defaults,
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextArea
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <ButtonSettings
      label="Button"
      onChange={(value) => onChangeItem("button", value)}
      value={settings.button}
    />
  </>
);

ArticleSliderWidget.adminFields = (items, onChange, settings) => (
  <>
    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        image: "http://placehold.it/400x300",
        title: "Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
        button: ButtonSettings.defaults,
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </>
);

export default ArticleSliderWidget;
