import React from "react";

export default ({ context: { mini_footer } }) => {
  const year = new Date().getFullYear();

  return (
    <div className="mini-footer dark-bg">
      <div className="container">
        <div className="mini-footer-inner">
          <div>&copy; {year} AIMS Education</div>

          <nav>
            {mini_footer.items.map((item) => (
              <a key={item.id} href={item.url}>
                {item.label}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
