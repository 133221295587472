import React from "react";

const StarsModalContext = React.createContext();

const StarsModalProvider = ({ children, openModal }) => (
  <StarsModalContext.Provider value={openModal}>
    {children}
  </StarsModalContext.Provider>
);

export { StarsModalProvider, StarsModalContext };
