import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Carousel, { Modal, ModalGateway } from "react-images";
import Img from "../frontend/Image";

const getLightboxImages = (list) =>
  list.map((item) => ({
    source: imageSrc(item.image, "lead"),
    caption: item.description,
  }));

const Item = ({ image, description }) => (
  <div className="inline-gallery">
    <figure>
      <Img src={image} variant="fixed_image" loading="lazy" />
      {description && (
        <figcaption dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </figure>
  </div>
);

const InlineGalleryWidget = ({ variant, list, containerSettings }) => {
  const [state, setState] = React.useState({ open: false, index: 0 });

  const openLightbox = (index) =>
    setState((state) => ({ ...state, open: true, index }));
  const closeLightbox = () => setState((state) => ({ ...state, open: false }));

  return (
    <Container settings={containerSettings}>
      <div className="inline-galleries">
        <div className="container">
          <div className="row">
            {list.map((item, idx) => (
              <div key={item.id} className={variant}>
                <a
                  href={imageSrc(item.image, "lead")}
                  onClick={(e) => {
                    e.preventDefault();
                    openLightbox(idx);
                  }}
                >
                  <Item {...item} />
                </a>
              </div>
            ))}
          </div>
        </div>

        <ModalGateway>
          {state.open ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                views={getLightboxImages(list)}
                currentIndex={state.index}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Container>
  );
};

InlineGalleryWidget.title = "Gallery (inline)";
InlineGalleryWidget.category = "Media";

InlineGalleryWidget.defaults = {
  variant: "col-6",
  list: [
    {
      id: 1,
      image: "http://placehold.it/800x600",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
    },
    {
      id: 2,
      image: "http://placehold.it/800x600",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

InlineGalleryWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-6", "2 columns"],
        ["col-4", "3 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        image: "http://placehold.it/800x600",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default InlineGalleryWidget;
