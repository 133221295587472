import React from "react";
import { MenuBuilder } from "@raketa-cms/raketa-menu-builder";
import { ThemeProvider } from "styled-components";
import { theme } from "@raketa-cms/raketa-mir";
import WIDGETS from "./menu-widgets";

export default ({ name, value }) => {
  const [menu, setMenu] = React.useState({
    tab: 0,
    items: value,
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <MenuBuilder
          widgets={WIDGETS}
          value={menu}
          onChange={(menu) => setMenu(menu)}
        />
      </ThemeProvider>

      <input type="hidden" name={name} value={JSON.stringify(menu.items)} />
    </>
  );
};
