const GraduateStatus = [
  { id: 1, value: "Graduate", label: "Graduate" },
  {
    id: 2,
    value: "Student",
    label: "Student",
  },
  {
    id: 3,
    value: "Non-graduate",
    label: "Non-graduate",
  },
];

export default GraduateStatus;
