import React from "react";
import { Container, ButtonSettings, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

const CallToAction = ({
  variant,
  height,
  image,
  mobileImage,
  overlay,
  title,
  description,
  button_1,
  button_2,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div
      className={`call-to-action ${
        variant === "col-8" ? "text-left" : "text-center"
      }`}
      style={{ height }}
    >
      <div
        className="desktop-background"
        style={{ backgroundImage: `url("${imageSrc(image, "lead")}")` }}
      >
        <div
          className="mobile-background"
          style={{
            backgroundImage: `url("${imageSrc(mobileImage, "image")}")`,
          }}
        >
          <div className="overlay" style={{ opacity: overlay }} />

          <div className="cta-inner">
            <div className="container">
              <div className="row">
                <div className={variant}>
                  <div className="content">
                    {title && (
                      <h1
                        className="main-title"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    )}
                    {description != "<p><br></p>" && (
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    )}

                    {(button_1.link && button_1.label) ||
                    (button_2.link && button_2.label) ? (
                      <div className="button-wrapper">
                        {button_1.link && button_1.label && (
                          <Button settings={button_1} className="lg" />
                        )}
                        {button_2.link && button_2.label && (
                          <Button settings={button_2} className="lg" />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CallToAction.title = "Call to Action";
CallToAction.category = "Media";
CallToAction.dialogSize = "large";

CallToAction.defaultProps = {
  description: "",
  button_1: {},
  button_2: {},
};

CallToAction.defaults = {
  height: "580px",
  variant: "shift-2",
  image: "https://placehold.it/1920x1080",
  mobileImage: "https://placehold.it/540x540",
  overlay: "0.5",
  title: "Call to Action",
  description:
    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!",
  button_1: ButtonSettings.defaults,
  button_2: ButtonSettings.defaults,
  containerSettings: {},
};

CallToAction.adminFields = {
  variant: {
    type: "select",
    options: [
      ["shift-2", "Centered"],
      ["col-8", "Left"],
    ],
  },
  height: { type: "text" },
  image: { type: "custom", component: ImagePicker },
  mobileImage: { type: "custom", component: ImagePicker },
  overlay: {
    type: "select",
    options: [
      ["0", "None"],
      ["0.1", "10%"],
      ["0.2", "20%"],
      ["0.3", "30%"],
      ["0.4", "40%"],
      ["0.5", "50%"],
      ["0.6", "60%"],
      ["0.7", "70%"],
      ["0.8", "80%"],
      ["0.9", "90%"],
      ["1", "100%"],
    ],
  },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button_1: { type: "button" },
  button_2: { type: "button" },
};

export default CallToAction;
