import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Link from "../frontend/Link";
import Img from "../frontend/Image";

const Item = ({ title, link, image }) => (
  <Link settings={link}>
    <div className="card">
      <Img src={image} variant="fixed_image" loading="lazy" alt={link.label} />

      <div className="content">
        <h3 className="title">{title}</h3>
      </div>
    </div>
  </Link>
);

const CardsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cards">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={variant}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CardsWidget.title = "Cards";
CardsWidget.category = "Media";

CardsWidget.defaults = {
  variant: "col-4",
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      title: "Title",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      title: "Title",
    },
    {
      id: 3,
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      title: "Title",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />
  </div>
);

CardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-6", "2 columns"],
        ["col-4", "3 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        link: LinkSettings.defaults,
        image: "http://placehold.it/800x600",
        title: "Title",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default CardsWidget;
