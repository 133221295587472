import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  ButtonSettings,
  TextArea,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const Tooltip = ({ text }) => (
  <span className="tooltip">
    <i className="icon" />

    <div className="tooltip-content">{text}</div>
  </span>
);

const ProgramHeaderWidget = ({
  background,
  background_mobile,
  overlay,
  overlay_theme,
  desktop_height,
  title,
  description,
  button_1,
  button_2,
  list,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="program-header-wrapper">
      <div
        className={`program-header ${overlay_theme} ${
          desktop_height || "medium"
        }`}
      >
        <div
          className="mobile-background"
          style={{
            backgroundImage: background_mobile
              ? `url("${imageSrc(background_mobile, "image")}")`
              : `url("${imageSrc(background, "image")}")`,
          }}
        >
          <div
            className="desktop-background"
            style={{
              backgroundImage: `url("${imageSrc(background, "lead")}")`,
            }}
          >
            <div className="overlay" style={{ opacity: overlay }} />

            <div className="container">
              <div className="hero-inner">
                <div className="content">
                  <div className="row">
                    <div className="shift-2">
                      <h1 className="title">{title}</h1>

                      {description !== "<p><br></p>" && (
                        <HtmlLinksDecoration
                          className="description"
                          text={description}
                        />
                      )}

                      {(button_1.label || button_2.label) && (
                        <div className="button-wrapper">
                          {button_1.label && (
                            <Button className="lg" settings={button_1} />
                          )}

                          {button_2.label && (
                            <Button className="lg" settings={button_2} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="program-details">
                    {list.map((item) => (
                      <div key={item.id} className="program-detail">
                        <h5 className="label">
                          {item.label}

                          {item.tooltip && <Tooltip text={item.tooltip} />}
                        </h5>

                        <HtmlLinksDecoration
                          className="value"
                          text={item.value}
                        />

                        {item.button.label && <Button settings={item.button} />}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="program-details-mobile">
        {list.map((item) => (
          <div key={item.id} className="program-detail">
            <h5 className="label">
              {item.label}

              {item.tooltip && <Tooltip text={item.tooltip} />}
            </h5>

            <HtmlLinksDecoration className="value" text={item.value} />

            {item.button.label && <Button settings={item.button} />}
          </div>
        ))}
      </div>
    </div>
  </Container>
);

ProgramHeaderWidget.title = "Program header";
ProgramHeaderWidget.category = "Media";

ProgramHeaderWidget.defaults = {
  background: "http://placehold.it/1920x1080",
  background_mobile: "http://placehold.it/540x540",
  overlay: "0.5",
  overlay_theme: "overlay-black",
  desktop_height: "medium",
  title: "Lead Image",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas velit debitis doloremque et dolor ad in, at modi nulla, dolores obcaecati. Labore maxime doloribus sapiente, ipsam blanditiis corrupti aspernatur cumque?</p>",
  button_1: ButtonSettings.defaults,
  button_2: ButtonSettings.defaults,
  list: [
    {
      id: 1,
      label: "Label",
      value: "Yes",
      tooltip: "Tooltip text goes here",
      button: ButtonSettings.defaults,
    },
    {
      id: 2,
      label: "Label",
      value: "Yes",
      tooltip: "Tooltip text goes here",
      button: ButtonSettings.defaults,
    },
    {
      id: 3,
      label: "Label",
      value: "Yes",
      tooltip: "Tooltip text goes here",
      button: ButtonSettings.defaults,
    },
    {
      id: 4,
      label: "Label",
      value: "Yes",
      tooltip: "Tooltip text goes here",
      button: ButtonSettings.defaults,
    },
    {
      id: 5,
      label: "Label",
      value: "Yes",
      tooltip: "Tooltip text goes here",
      button: ButtonSettings.defaults,
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Label"
      onChange={(value) => onChangeItem("label", value)}
      value={settings.label}
    />

    <RichText
      label="Value"
      onChange={(value) => onChangeItem("value", value)}
      value={settings.value}
    />

    <TextArea
      label="Tooltip"
      onChange={(value) => onChangeItem("tooltip", value)}
      value={settings.tooltip}
    />

    <ButtonSettings
      label="Button"
      onChange={(value) => onChangeItem("button", value)}
      value={settings.button}
    />
  </div>
);

ProgramHeaderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <ImagePicker
      label="Background"
      onChange={(value) => onChange("background", value)}
      value={settings.background}
    />

    <ImagePicker
      label="Background mobile"
      onChange={(value) => onChange("background_mobile", value)}
      value={settings.background_mobile}
    />

    <SelectMenu
      label="Overlay"
      options={[
        ["0", "None"],
        ["0.1", "10%"],
        ["0.2", "20%"],
        ["0.3", "30%"],
        ["0.4", "40%"],
        ["0.5", "50%"],
        ["0.6", "60%"],
        ["0.7", "70%"],
        ["0.8", "80%"],
        ["0.9", "90%"],
        ["1", "100%"],
      ]}
      onChange={(value) => onChange("overlay", value)}
      value={settings.overlay}
    />

    <SelectMenu
      label="Overlay Theme"
      options={[
        ["overlay-black", "Black"],
        ["overlay-brand", "Brand"],
      ]}
      onChange={(value) => onChange("overlay_theme", value)}
      value={settings.overlay_theme}
    />

    <SelectMenu
      label="Desktop Height"
      options={[
        ["short", "Short"],
        ["medium", "Medium"],
        ["long", "Long"],
      ]}
      onChange={(value) => onChange("desktop_height", value)}
      value={settings.desktop_height}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChange("description", value)}
      value={settings.description}
    />

    <ButtonSettings
      label="Button 1"
      onChange={(value) => onChange("button_1", value)}
      value={settings.button_1}
    />

    <ButtonSettings
      label="Button 2"
      onChange={(value) => onChange("button_2", value)}
      value={settings.button_2}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        label: "Label",
        value: "Yes",
        tooltip: "Tooltip text goes here",
        button: ButtonSettings.defaults,
      }}
      primaryField="label"
      onChangeList={onChange}
    />
  </div>
);

export default ProgramHeaderWidget;
