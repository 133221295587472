import HtmlWidget from "./HtmlWidget";
import SectionTitleWidget from "./SectionTitleWidget";
import TextWidget from "./TextWidget";
import ImageWidget from "./ImageWidget";
import VideoWidget from "./VideoWidget";
import ArticleHeaderSimpleWidget from "./ArticleHeaderSimpleWidget";
import LeadImageWidget from "./LeadImageWidget";
import AccentWidget from "./AccentWidget";
import TextSidebarWidget from "./TextSidebarWidget";
import NavigationWidget from "./NavigationWidget";
import InlineGalleryWidget from "./InlineGalleryWidget";
import LogosWidget from "./LogosWidget";
import ArticlesWidget from "./ArticlesWidget";
import TableWidget from "./TableWidget";
import ButtonsWidget from "./ButtonsWidget";
import SliderWidget from "./SliderWidget";
import SpotlightSliderWidget from "./SpotlightSliderWidget";
import ArticleSliderWidget from "./ArticleSliderWidget";
import ProgramHeaderWidget from "./ProgramHeaderWidget";
import ArticleListSmallWidget from "./ArticleListSmallWidget";
import ImageTextWidget from "./ImageTextWidget";
import PersonSpotlightWidget from "./PersonSpotlightWidget";
import CardsWidget from "./CardsWidget";
import ProfileWidget from "./ProfileWidget";
import TextColumnsWidget from "./TextColumnsWidget";
import BreadcrumbsWidget from "./BreadcrumbsWidget";
import SimpleLeadImageWidget from "./SimpleLeadImageWidget";
import SquareFeedWidget from "./SquareFeedWidget";
import StarsWidget from "./StarsWidget";
import OpenHouseWidget from "./OpenHouseWidget";
import CallToActionWidget from "./CallToActionWidget";
import SimplifiedStarsWidget from "./SimplifiedStarsWidget";
import AdvancedLogosWidget from "./AdvancedLogosWidget";
import TranscriptRequestWidget from "./TranscriptRequestWidget";
import PageHeroWidget from "./PageHeroWidget";
import ProgramHeroWidget from "./ProgramHeroWidget";
import QuestionnaireFormWidget from "./QuestionnaireFormWidget";
import AlumniFormWidget from "./AlumniFormWidget";

export default {
  HtmlWidget,
  SectionTitleWidget,
  TextWidget,
  ImageWidget,
  VideoWidget,
  ArticleHeaderSimpleWidget,
  LeadImageWidget,
  AccentWidget,
  TextSidebarWidget,
  NavigationWidget,
  InlineGalleryWidget,
  LogosWidget,
  ArticlesWidget,
  TableWidget,
  ButtonsWidget,
  SliderWidget,
  SpotlightSliderWidget,
  ArticleSliderWidget,
  ProgramHeaderWidget,
  ArticleListSmallWidget,
  ImageTextWidget,
  PersonSpotlightWidget,
  CardsWidget,
  ProfileWidget,
  TextColumnsWidget,
  BreadcrumbsWidget,
  SimpleLeadImageWidget,
  SquareFeedWidget,
  StarsWidget,
  OpenHouseWidget,
  CallToActionWidget,
  SimplifiedStarsWidget,
  AdvancedLogosWidget,
  TranscriptRequestWidget,
  PageHeroWidget,
  ProgramHeroWidget,
  QuestionnaireFormWidget,
  AlumniFormWidget,
};
