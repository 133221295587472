import React from "react";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import { MediaManagerContext } from "@raketa-cms/raketa-image-picker";
import LandingLayout from "./frontend/LandingLayout";
import Layout from "./frontend/Layout";
import LIBRARY from "./widgets";
import { HostProvider, AuthTokenContext } from "./providers";
import MediaManager from "./MediaManager";

const THEMES = [
  ["none", "None"],
  ["light", "Light"],
  ["dark", "Dark"],
  ["brand", "Brand"],
  ["white-columns", "White Columns"],
  ["brand-court", "Brand Court"],
];

const pickLayout = ({ layout }) => {
  if (layout === "landing") {
    return LandingLayout;
  }

  return Layout;
};

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mediaManager = new MediaManager("/images/client/");

const AdminBuilder = ({
  page: defaultPage,
  page_context,
  host,
  save_url,
  back_url,
  host_url,
  auth_token,
}) => {
  const [dirty, setDirty] = React.useState(false);
  const [page, setPage] = React.useState(defaultPage);

  const handleChange = (page) => {
    setPage(page);
    setDirty(true);
  };

  const handleSave = (page) => {
    fetch(save_url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ widgets: page.widgets }),
    })
      .then(() => {
        setDirty(false);
      })
      .catch((error) => {
        console.error(error);
        alert("An error occured saving this page. ");
      });
  };

  const LayoutComponent = pickLayout(page);
  return (
    <ErrorBoundary>
      <MediaManagerContext.Provider value={mediaManager}>
        <HostProvider url={host_url}>
          <AuthTokenContext.Provider value={auth_token}>
            <LayoutComponent page={page} pageContext={page_context}>
              <PageBuilder
                host={host}
                dirty={dirty}
                library={LIBRARY}
                adminLibrary={LIBRARY}
                themes={THEMES}
                page={page}
                onChange={handleChange}
                onSave={handleSave}
                onExit={() => (window.location.href = back_url)}
              />
            </LayoutComponent>
          </AuthTokenContext.Provider>
        </HostProvider>
      </MediaManagerContext.Provider>
    </ErrorBoundary>
  );
};

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
