import React from "react";

const Pagination = ({ pagination }) => {
  if (!pagination) {
    return null;
  }

  return (
    <div className="spacing-both">
      <div className="will-paginate">
        <div dangerouslySetInnerHTML={{ __html: pagination }} />
      </div>
    </div>
  );
};

export default Pagination;
