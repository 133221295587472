import React from "react";
import { StarsModalProvider } from "../providers";
import StarsModal from "./StarsModal";
import SimplifiedStarsModal from "./SimplifiedStarsModal";

const Modal = ({ params, onClose }) => {
  const { modal, ...rest } = params;

  if (modal == "full") {
    return <StarsModal onClose={onClose} />;
  }

  if (modal === "simple") {
    return <SimplifiedStarsModal onClose={onClose} {...rest} />;
  }

  return null;
};

const LayoutModals = ({ children }) => {
  const [state, setState] = React.useState({ open: false, params: {} });

  return (
    <StarsModalProvider
      openModal={(modalParams) =>
        setState({
          open: true,
          params: { ...modalParams },
        })
      }
    >
      {children}

      {state.open && (
        <Modal
          params={state.params}
          onClose={() => setState({ ...state, open: false })}
        />
      )}
    </StarsModalProvider>
  );
};

export default LayoutModals;
