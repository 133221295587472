import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const ImageWidget = ({ variant, image, description, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="image">
      <div className="container">
        <div className="row">
          <div className={variant}>
            <figure>
              <Img src={image} variant="lead" loading="lazy" />
              {description && description !== "<p><br></p>" && (
                <HtmlLinksDecoration className="credits" text={description} />
              )}
            </figure>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

ImageWidget.title = "Image";
ImageWidget.category = "General";
ImageWidget.primaryField = "description";

ImageWidget.defaults = {
  variant: "col-12",
  image: "https://placehold.it/1920x1080",
  description: "<p>Example</p>",
  containerSettings: {},
};

ImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["col-12", "Full width"],
      ["shift-1", "Shift 1"],
      ["shift-2", "Shift 2"],
      ["shift-3", "Shift 3"],
      ["shift-4", "Shift 4"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  description: { type: "custom", component: RichText },
};

export default ImageWidget;
