import React from "react";
import { Container, List, LinkSettings } from "@raketa-cms/raketa-cms";
import Link from "../frontend/Link";

const BreadcrumbsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="breadcrumbs">
      <div className="container">
        {list.map((item) => (
          <Link key={item.id} settings={item.link} />
        ))}
      </div>
    </div>
  </Container>
);

BreadcrumbsWidget.title = "Breadcrumbs";
BreadcrumbsWidget.category = "General";

BreadcrumbsWidget.defaults = {
  list: [
    { id: 1, link: LinkSettings.defaults },
    { id: 2, link: LinkSettings.defaults },
    { id: 3, link: LinkSettings.defaults },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

BreadcrumbsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{ link: LinkSettings.defaults }}
      primaryField="link.label"
      onChangeList={onChange}
    />
  </div>
);

export default BreadcrumbsWidget;
