import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Link from "../frontend/Link";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const DAY_OPTIONS = [
  ["Mon", "Monday"],
  ["Tue", "Tuesday"],
  ["Wed", "Wednesday"],
  ["Thu", "Thursday"],
  ["Fri", "Friday"],
  ["Sat", "Saturday"],
  ["Sun", "Sunday"],
];

const MONTH_OPTIONS = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["Jun", "June"],
  ["Jul", "July"],
  ["Aug", "August"],
  ["Sep", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];

const EventPreview = ({ date, month, day }) => (
  <div className="event-preview">
    <div className="event-preview-inner">
      <div className="date">{date}</div>
      <div className="month">{month}</div>
      <div className="day">{day}</div>
    </div>
  </div>
);

const DateMeta = ({ day, month, date }) => {
  if (!day) {
    return `${month} ${date}`;
  }

  if (!month) {
    return "";
  }

  const longDay = DAY_OPTIONS.find((d) => d[0] === day)[1];
  const longMonth = MONTH_OPTIONS.find((d) => d[0] === month)[1];

  return [longDay, `${longMonth} ${date}`].join(", ");
};

const Item = ({
  link,
  variant,
  image,
  title,
  day,
  date,
  month,
  description,
}) => (
  <div className="article-item">
    <div className="article-image">
      {variant === "article" && (
        <Link settings={link}>
          <Img src={image} variant="square" loading="lazy" alt={link.label} />
        </Link>
      )}

      {variant === "event" && (
        <Link settings={link}>
          <EventPreview date={date} month={month} day={day} />
        </Link>
      )}
    </div>

    <div className="article-content">
      <h4 className="title">
        <Link settings={link}>{title}</Link>
      </h4>
      <div className="meta">
        <DateMeta day={day} month={month} date={date} />
      </div>
      <HtmlLinksDecoration className="description" text={description} />
    </div>
  </div>
);

const ArticleListSmallWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="article-list-small">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={variant}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ArticleListSmallWidget.title = "Article list small";
ArticleListSmallWidget.category = "Media";

ArticleListSmallWidget.defaults = {
  variant: "col-6",
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      variant: "event",
      image: "http://placehold.it/540x540",
      title: "Title",
      day: "Mon",
      date: "1",
      month: "Feb",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      variant: "article",
      image: "http://placehold.it/540x540",
      title: "Title",
      day: "Mon",
      date: "1",
      month: "Feb",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <SelectMenu
      label="Variant"
      options={[
        ["article", "Article"],
        ["event", "Event"],
      ]}
      value={settings.variant}
      onChange={(value) => onChangeItem("variant", value)}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <SelectMenu
      label="Day"
      options={DAY_OPTIONS}
      value={settings.day}
      onChange={(value) => onChangeItem("day", value)}
    />

    <TextInput
      label="Date"
      onChange={(value) => onChangeItem("date", value)}
      value={settings.date}
    />

    <SelectMenu
      label="Month"
      options={MONTH_OPTIONS}
      value={settings.month}
      onChange={(value) => onChangeItem("month", value)}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

ArticleListSmallWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-6", "2 columns"],
        ["col-4", "3 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        link: LinkSettings.defaults,
        variant: "event",
        image: "http://placehold.it/540x540",
        title: "Title",
        day: "Mon",
        date: "1",
        month: "Feb",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default ArticleListSmallWidget;
