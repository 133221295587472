import React from "react";
import { Container, List, LinkSettings } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Swiper from "react-id-swiper";
import Link from "../frontend/Link";
import Img from "../frontend/Image";

const Item = ({ link, logo }) => {
  if (link.link) {
    return (
      <Link settings={link}>
        <Img src={logo} variant="original" loading="lazy" alt={link.label} />
      </Link>
    );
  } else {
    return (
      <Img src={logo} variant="original" loading="lazy" alt={link.label} />
    );
  }
};

const LogosWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="logos">
      <div className="container">
        <div className="logos-inner">
          {list.map((item) => (
            <div key={item.id} className="logo">
              <Item {...item} />
            </div>
          ))}
        </div>

        <div className="logos-mobile">
          <Swiper
            rebuildOnUpdate
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={30}
            paginationClickable
          >
            {list.map((item) => (
              <div key={item.id} className="logo">
                <Item {...item} />
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  </Container>
);

LogosWidget.title = "Logos";
LogosWidget.category = "Media";

LogosWidget.defaults = {
  list: [
    { id: 1, link: LinkSettings.defaults, logo: "http://placehold.it/400x300" },
    { id: 2, link: LinkSettings.defaults, logo: "http://placehold.it/400x300" },
    { id: 3, link: LinkSettings.defaults, logo: "http://placehold.it/400x300" },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Logo"
      onChange={(value) => onChangeItem("logo", value)}
      value={settings.logo}
    />
  </div>
);

LogosWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        link: LinkSettings.defaults,
        logo: "http://placehold.it/400x300",
      }}
      primaryField="link.label"
      onChangeList={onChange}
    />
  </div>
);

export default LogosWidget;
