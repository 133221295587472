import React from "react";
import { Container, SelectMenu } from "@raketa-cms/raketa-cms";
import TableBuilder from "../admin/TableBuilder";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const TableWidget = ({
  show_header,
  tableContent,
  variant,
  containerSettings,
}) => {
  const { table, columnClasses = [] } = tableContent;

  return (
    <Container settings={containerSettings}>
      <div className="table-wrapper">
        <div className="container">
          <div className="row">
            <div className={variant}>
              <table>
                {show_header === "yes" && (
                  <thead>
                    <tr>
                      {table[0].map((col, cidx) => (
                        <th key={cidx} className={columnClasses[cidx]}>
                          <HtmlLinksDecoration text={col} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                )}

                <tbody>
                  {table.slice(1).map((row, ridx) => (
                    <tr key={ridx}>
                      {row.map((col, cidx) => (
                        <td key={cidx} className={columnClasses[cidx]}>
                          <HtmlLinksDecoration text={col} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

TableWidget.title = "Table";
TableWidget.category = "General";

TableWidget.defaults = {
  variant: "col-12",
  show_header: "yes",
  tableContent: {
    table: [["Col 1", "Col 2", "Col 3"]],
    columnClasses: ["", "", ""],
  },
  containerSettings: {},
};

TableWidget.adminFields = (_, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-12", "12 columns"],
        ["col-10", "10 columns"],
        ["col-8", "8 columns"],
        ["shift-1", "Shift 1"],
        ["shift-2", "Shift 2"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <SelectMenu
      label="Show header"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.show_header}
      onChange={(value) => onChange("show_header", value)}
    />

    <TableBuilder
      tableContent={settings.tableContent}
      onChange={(value) => onChange("tableContent", value)}
    />
  </div>
);

export default TableWidget;
