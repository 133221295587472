import React from "react";

const Input = ({
  type,
  label,
  name,
  error,
  value,
  placeholder,
  id,
  required,
  hint,
  ...rest
}) => (
  <div className={`form-group ${error ? "has-error" : ""}`}>
    {label && (
      <label className="control-label">
        {label}
        {required ? "*" : ""}
      </label>
    )}

    <input
      className="form-control"
      type={type}
      name={name}
      defaultValue={value}
      placeholder={placeholder ? placeholder : ""}
      required={required ? required : false}
      id={id}
      {...rest}
    />

    {hint && <span className="help-block">{hint}</span>}
    {error && <span className="help-block">{error}</span>}
  </div>
);

const Checkbox = ({
  type,
  label,
  name,
  error,
  value,
  placeholder,
  id,
  required,
  labelClass,
  ...rest
}) => (
  <div className={`form-group ${error ? "has-error" : ""}`}>
    <label
      className={`control-label ${labelClass ? labelClass : "checkbox-label"}`}
    >
      <div>
        <input
          className="form-control"
          type={type}
          name={name}
          defaultValue={value}
          placeholder={placeholder ? placeholder : ""}
          required={required ? required : false}
          id={id}
          {...rest}
        />
      </div>

      <div>
        {typeof label === "function"
          ? label()
          : `${label} ${required ? "*" : ""}`}
      </div>
    </label>

    {error && <span className="help-block">{error}</span>}
  </div>
);

const areCharactersAllowed = (allowedCharacters, value) =>
  [...value].every((char) => allowedCharacters.includes(char));

const RestrictiveInput = ({
  type,
  label,
  name,
  error,
  value,
  placeholder,
  id,
  required,
  allowedCharacters,
  maxLength,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = React.useState("");
  const allowedLength = parseInt(maxLength);

  const inputRef = React.createRef();

  return (
    <div className={`form-group ${error ? "has-error" : ""}`}>
      {label && (
        <label className="control-label">
          {label}
          {required ? "*" : ""}
        </label>
      )}

      <input
        ref={inputRef}
        className="form-control"
        type="text"
        name={name}
        value={currentValue}
        placeholder={placeholder ? placeholder : ""}
        required={required ? required : false}
        id={id}
        onChange={(e) => {
          const newValue = e.target.value;

          if (
            newValue == "" ||
            (newValue.length <= allowedLength &&
              areCharactersAllowed(allowedCharacters, newValue))
          ) {
            setCurrentValue(newValue);
          } else {
            setCurrentValue(currentValue);
          }
        }}
        {...rest}
      />

      {error && <span className="help-block">{error}</span>}
    </div>
  );
};

const TextArea = ({ label, name, error, value, placeholder, id, required }) => (
  <div className={`form-group ${error ? "has-error" : ""}`}>
    {label && (
      <label className="control-label">
        {label}
        {required ? "*" : ""}
      </label>
    )}

    <textarea
      className="form-control"
      id={id}
      name={name}
      placeholder={placeholder ? placeholder : ""}
      required={required ? required : false}
      defaultValue={value}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);

const renderSelectOptions = (options) =>
  options &&
  options.map((item) => (
    <option key={item.id} value={item.value}>
      {item.label}
    </option>
  ));

const SelectInput = ({
  name,
  label,
  error,
  required,
  id,
  placeholder,
  options,
  defaultValue = null,
  disabled = false,
  ...rest
}) => (
  <div className={`form-group ${error ? "has-error" : ""}`}>
    {label && (
      <label className="control-label">
        {label}
        {required ? "*" : ""}
      </label>
    )}

    <select
      className="form-control"
      name={name}
      required={required ? required : false}
      id={id}
      disabled={disabled}
      defaultValue={defaultValue}
      {...rest}
    >
      {(placeholder || placeholder === "") && (
        <option value="">{placeholder}</option>
      )}
      {renderSelectOptions(options)}
    </select>

    {error && <span className="help-block">{error}</span>}
  </div>
);

const HiddenInput = ({ name, id, value }) => (
  <div className="form-group">
    <input
      className="form-control"
      type="hidden"
      name={name}
      defaultValue={value}
      id={id}
    />
  </div>
);

const RadioButtons = ({ label, required, name, options, onChange }) => {
  const [value, setValue] = React.useState(null);
  const requiredInput = required && (value?.length || 0) === 0;

  const radioOpts = (radio) => {
    const opts = {
      type: "radio",
      name: name,
      value: radio.value,
    };

    if (requiredInput) {
      opts["required"] = true;
    }

    opts["checked"] = value == radio.value;

    opts["onChange"] = (event) => {
      const checked = event.target.checked;

      if (checked) {
        setValue(radio.value);
        onChange && onChange(radio.value);
      }
    };

    return opts;
  };

  return (
    <div className="form-group">
      {label && (
        <label className="control-label">
          {label}
          {required ? "*" : ""}
        </label>
      )}

      {options.map((radio) => (
        <label
          key={radio.value}
          className="radio"
          htmlFor={`${radio.id}-${radio.value}`}
        >
          <input id={`${radio.id}-${radio.value}`} {...radioOpts(radio)} />
          {radio.label}
        </label>
      ))}
    </div>
  );
};

const Checkboxes = ({ label, required, name, options }) => {
  const [value, setValue] = React.useState([]);
  const requiredInput = required && (value?.length || 0) === 0;

  const checkboxOpts = (checkbox) => {
    const opts = {
      type: "checkbox",
      name: `${name}[]`,
      value: checkbox.value,
    };

    if (requiredInput) {
      opts["required"] = true;
    }

    opts["checked"] = value.includes(checkbox.value);

    opts["onChange"] = (event) => {
      const checked = event.target.checked;

      if (checked) {
        setValue((current) => {
          const tmp = current.filter((v) => v !== checkbox.value);

          tmp.push(checkbox.value);

          return tmp;
        });
      } else {
        setValue((current) => {
          return current.filter((v) => v !== checkbox.value);
        });
      }
    };

    return opts;
  };

  return (
    <div className="form-group">
      {label && (
        <label className="control-label">
          {label}
          {required ? "*" : ""}
        </label>
      )}

      {options.map((checkbox) => (
        <label key={checkbox.value} className="checkbox">
          <input {...checkboxOpts(checkbox)} />
          {checkbox.label}
        </label>
      ))}
    </div>
  );
};

const Inputs = {
  textarea: TextArea,
  select: SelectInput,
  text: Input,
  restirctiveText: RestrictiveInput,
  email: Input,
  tel: Input,
  url: Input,
  number: Input,
  hidden: HiddenInput,
  checkbox: Checkbox,
  "radio-group": RadioButtons,
  "checkbox-group": Checkboxes,
};

const UncontrolledInput = (props) => {
  const { type } = props;

  const Component = Inputs[type];

  if (Component) {
    return Component(props);
  }

  throw `Unknown field type: ${type}`;
};

export default UncontrolledInput;
