import React from "react";
import Layout from "../frontend/Layout";
import { shortDateFormat } from "../helpers/date";
import Pagination from "../frontend/Pagination";
import Img from "../frontend/Image";

const Item = ({ title, slug, image, summary, publish_on }) => (
  <div className="article-card">
    <a href={`/blog/${slug}`}>
      <div className="image">
        {image && (
          <Img
            src={image}
            variant="card_horizontal"
            loading="lazy"
            alt={title}
          />
        )}
      </div>
    </a>

    <div className="title-wrapper">
      <h4 className="title">
        <a href={`/blog/${slug}`}>{title}</a>
      </h4>

      <div className="meta">{shortDateFormat(publish_on)}</div>
    </div>

    {summary && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    )}
  </div>
);

export default ({ page_context, pagination, articles }) => (
  <Layout pageContext={page_context}>
    <div className="spacing-both light-bg">
      <div className="text-center">
        <div className="container">
          <h2>Healthcare Training Blog</h2>
        </div>
      </div>
    </div>

    <div className="spacing-both">
      <div className="article-cards">
        <div className="container">
          <div className="full">
            <div className="row">
              {articles.length > 0 &&
                articles.map((item) => (
                  <div key={item.id} className="col-4">
                    <Item {...item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Pagination pagination={pagination} />
  </Layout>
);
