import React from "react";
import Swiper from "react-id-swiper";
import { Container, List, SelectMenu, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Img from "../frontend/Image";

const SliderWidget = ({
  variant,
  size,
  autoplay,
  delay,
  containerSettings,
  list,
}) => {
  const swiperOptions =
    autoplay === "yes" ? { autoplay: { delay: parseInt(delay, 10) } } : {};

  return (
    <Container settings={containerSettings} className="slider">
      <div className={variant}>
        <div className="row">
          <div className={size}>
            {list.length !== 1 && (
              <Swiper
                rebuildOnUpdate
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                slidesPerView={1}
                spaceBetween={0}
                paginationClickable={true}
                {...swiperOptions}
              >
                {list.map((slide) => (
                  <div key={slide.id} className="slide">
                    <Img
                      src={slide.image}
                      variant="fullscreen"
                      loading="lazy"
                    />
                  </div>
                ))}
              </Swiper>
            )}

            {list.length === 1 && (
              <div className="slide">
                <Img src={list[0].image} variant="fullscreen" loading="lazy" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

SliderWidget.title = "Slider";
SliderWidget.category = "Media";

SliderWidget.defaults = {
  variant: "container",
  size: "col-12",
  autoplay: "no",
  delay: 5000,
  list: [
    { id: 1, image: "http://placehold.it/1920x1080" },
    { id: 2, image: "http://placehold.it/1920x1080" },
    { id: 3, image: "http://placehold.it/1920x1080" },
    { id: 4, image: "http://placehold.it/1920x1080" },
  ],
  containerSettings: { theme: "white" },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

SliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["container", "Contained"],
        ["full-width", "Full width"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <SelectMenu
      label="Size"
      options={[
        ["col-12", "Edge to edge"],
        ["col-8", "8 columns"],
        ["col-10", "10 columns"],
        ["shift-1", "Shift 1"],
        ["shift-2", "Shift 2"],
      ]}
      value={settings.size}
      onChange={(value) => onChange("size", value)}
    />

    <SelectMenu
      label="Autoplay"
      options={[
        ["no", "No"],
        ["yes", "Yes"],
      ]}
      value={settings.autoplay}
      onChange={(value) => onChange("autoplay", value)}
    />

    <TextInput
      label="Delay"
      onChange={(value) => onChange("delay", value)}
      value={settings.delay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      template={{ image: "http://placehold.it/1920x1080" }}
      items={items}
    />
  </div>
);

export default SliderWidget;
