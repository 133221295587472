import React from "react";
import Layout from "../frontend/Layout";
import { shortDateFormat } from "../helpers/date";
import Pagination from "../frontend/Pagination";
import Img from "../frontend/Image";

const Item = ({ title, slug, image, summary, starts_at, ends_at }) => (
  <div className="article-card">
    <a href={`/events/${slug}`}>
      <div className="image">
        {image && (
          <Img
            src={image}
            variant="card_horizontal"
            loading="lazy"
            alt={title}
          />
        )}
      </div>
    </a>

    <div className="title-wrapper">
      <h4 className="title">
        <a href={`/events/${slug}`}>{title}</a>
      </h4>

      <div className="meta">
        {shortDateFormat(starts_at) === shortDateFormat(ends_at) ? (
          <span>{shortDateFormat(starts_at)}</span>
        ) : (
          <span>
            {shortDateFormat(starts_at)} - {shortDateFormat(ends_at)}
          </span>
        )}
      </div>
    </div>

    {summary && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    )}
  </div>
);

export default ({ page_context, pagination, events, title }) => (
  <Layout pageContext={page_context}>
    <div className="spacing-both light-bg">
      <div className="text-center">
        <div className="container">
          <h2>{title}</h2>
        </div>
      </div>
    </div>

    {events.length > 0 && (
      <div className="spacing-both">
        <div className="article-cards">
          <div className="container">
            <div className="full">
              <div className="row">
                {events.map((item) => (
                  <div key={item.id} className="col-4">
                    <Item {...item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

    {events.length === 0 && (
      <div className="spacing-both-lg">
        <div className="container">
          <p className="text-center">
            More events coming soon! We’ll be updating our event calendar in the
            coming days.
            <br />
            Please <a href="/events/archive">click here</a> to view some of our
            recent events.
          </p>
        </div>
      </div>
    )}

    <Pagination pagination={pagination} />
  </Layout>
);
