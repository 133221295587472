const FreeTranscript = [
  { id: 1, value: "Yes", label: "Yes" },
  {
    id: 2,
    value: "No",
    label: "No",
  },
  {
    id: 3,
    value: "N/A",
    label: "N/A",
  },
];

export default FreeTranscript;
