import React from "react";
import Layout from "../frontend/Layout";
import Pagination from "../frontend/Pagination";

const URLS = {
  blog: "/blog",
  news_item: "/news",
  article: "/blog",
  event: "/events",
  page: "",
};

const itemUrl = ({ type, slug }) => `${URLS[type]}/${slug}`;

const Item = ({ hostUrl, item: { title, slug, type, description } }) => {
  const url = itemUrl({ slug, type });

  return (
    <div className="spacing-both-sm">
      <div className="title-wrapper">
        <h4 className="title">
          <a href={url}>{title}</a>
        </h4>

        <a className="muted" href={url}>
          <small>
            https://
            {hostUrl}
            {url}
          </small>
        </a>
      </div>

      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  );
};

export default ({ pagination, host_url, page_context, results, term }) => (
  <Layout pageContext={page_context}>
    {console.log("Pagination: ", pagination)}
    <div className="search-results">
      <div className="spacing-both light-bg">
        <div className="text-center">
          <div className="container">
            <h4>Results for "{term}"</h4>
          </div>
        </div>
      </div>

      <div className="container spacing-both">
        <div className="row">
          {results.length > 0 && (
            <div className="col-10">
              {results.map((item) => (
                <div key={item.slug}>
                  <Item hostUrl={host_url} item={item} />
                </div>
              ))}
            </div>
          )}
          {results.length === 0 && (
            <div className="col-12">
              <div className="text-center">
                <p>No results found for "{term}"</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

    <Pagination pagination={pagination} />
  </Layout>
);
