import React from "react";
import { SelectMenu, List } from "@raketa-cms/raketa-cms";
import AimsEvents from "../frontend/Forms/AimsEvents";

const ListItem = ({ settings, onChangeItem }) => (
  <>
    <SelectMenu
      label="Event"
      options={AimsEvents.map((event) => [event.value, event.label])}
      value={settings.event}
      onChange={(value) => onChangeItem("event", value)}
    />
  </>
);

function AimsEventsInput({ value, onChange }) {
  return (
    <List
      label="Events"
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={value}
      template={{
        program: "",
      }}
      primaryField="event"
      onChangeList={(_key, list) => onChange(list)}
    />
  );
}

AimsEventsInput.defaults = AimsEvents.defaults;

export default AimsEventsInput;
