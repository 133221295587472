import React from "react";
import {
  Container,
  List,
  SelectMenu,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import Link from "../frontend/Link";

const NavigationWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="navigation">
      <div className={`navigation-inner ${variant}`}>
        {list.map((item) => (
          <Link key={item.id} settings={item.link} />
        ))}
      </div>
    </div>
  </Container>
);

NavigationWidget.title = "Navigation";
NavigationWidget.category = "General";

NavigationWidget.defaults = {
  variant: "container",
  list: [
    { id: 1, link: LinkSettings.defaults },
    { id: 2, link: LinkSettings.defaults },
    { id: 3, link: LinkSettings.defaults },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

NavigationWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["container", "Contained"],
        ["full-width", "Full width"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{ link: LinkSettings.defaults }}
      primaryField="link.label"
      onChangeList={onChange}
    />
  </div>
);

export default NavigationWidget;
