import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

const TextWidget = ({ visual, align, variant, text, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className={`text ${visual} ${align}`}>
      <div className="container">
        <div className="row">
          <div className={variant}>
            <HtmlLinksDecoration className="content" text={text} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

TextWidget.title = "Text";
TextWidget.category = "General";
TextWidget.primaryField = "text";

TextWidget.defaults = {
  visual: "normal",
  align: "text-left",
  variant: "col-8",
  text: "<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt natus nobis nihil, consequuntur mollitia iure architecto ipsum alias, accusamus dolores eaque aliquam illum fugit repellendus. Corrupti harum quam blanditiis dicta.</p>",
  containerSettings: {},
};

TextWidget.adminFields = {
  visual: {
    type: "select",
    options: [
      ["normal", "Normal"],
      ["inset", "Inset"],
      ["inset-border", "Inset with border"],
    ],
  },
  align: {
    type: "select",
    options: [
      ["text-left", "Left"],
      ["text-center", "Center"],
    ],
  },
  variant: {
    type: "select",
    options: [
      ["col-8", "8 columns"],
      ["col-10", "10 columns"],
      ["shift-1", "Shift 1"],
      ["shift-2", "Shift 2"],
      ["shift-3", "Shift 3"],
      ["shift-4", "Shift 4"],
    ],
  },
  text: { type: "custom", component: RichText },
};

export default TextWidget;
