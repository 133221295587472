import React from "react";
import { HostConsumer } from "../providers";

const documentPreviewRegexp = new RegExp(
  /^.+\/documents\/.+\/preview\/.+$/,
  "i"
);

const shouldDecorate = (hostUrl, href) => {
  if (documentPreviewRegexp.test(href)) {
    return true;
  }

  if (href.startsWith("/")) {
    return false;
  }

  return !href.includes(hostUrl);
};

// Do not touch. It works on Internet Explorer!
const decorateText = (text, hostUrl) => {
  let newText = text;
  const regExp = new RegExp(/<a href=\"(.+?)\">/, "g");

  while (true) {
    let matchResult = regExp.exec(text);

    if (matchResult === null) {
      return newText;
    }

    let [anchor, url] = matchResult;

    if (shouldDecorate(hostUrl, url)) {
      newText = newText.replace(
        anchor,
        anchor.replace("<a", `<a rel="nofollow" target="_blank" `)
      );
    }
  }

  return newText;
};

const HtmlLinksDecoration = ({ text, className }) => (
  <HostConsumer>
    {(hostUrl) => {
      return (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: decorateText(text, hostUrl),
          }}
        />
      );
    }}
  </HostConsumer>
);

export default HtmlLinksDecoration;
