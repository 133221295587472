import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import AlumniForm from "./AlumniForm";

const AlumniFormWidget = ({ containerSettings }) => {
  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <AlumniForm />
          </div>
        </div>
      </div>
    </Container>
  );
};

AlumniFormWidget.title = "Alumni Form";
AlumniFormWidget.category = "Forms";

AlumniFormWidget.defaults = {
  containerSettings: {},
};

AlumniFormWidget.adminFields = {};

export default AlumniFormWidget;
