import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Link from "../frontend/Link";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const Item = ({ title, link, image, description }) => (
  <div className="article">
    <Link settings={link}>
      <Img src={image} variant="fixed_image" loading="lazy" alt={link.label} />
    </Link>

    <div className="content">
      <h4 className="title">
        <Link settings={link}>{title}</Link>
      </h4>

      <HtmlLinksDecoration className="description" text={description} />
    </div>
  </div>
);

const variants = {
  2: 6,
  3: 4,
};

const ArticlesWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="articles">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={`col-${variants[variant]}`}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ArticlesWidget.title = "Articles";
ArticlesWidget.category = "Feeds";

ArticlesWidget.defaults = {
  variant: 2,
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

ArticlesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, "2 columns"],
        [3, "3 columns"],
        [4, "4 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        title: "Title",
        link: LinkSettings.defaults,
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/800x600",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default ArticlesWidget;
