import React from "react";
import Input from "../../frontend/UncontrolledInput";
import { AuthTokenContext } from "../../providers";

const AlumniForm = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const token = React.useContext(AuthTokenContext);

  const updateData = (key, value) => {
    setFormData((current) => {
      current[key] = value;

      return { ...current };
    });
  };

  return (
    <div className="q-form">
      <form
        method="POST"
        action="/alumni"
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <Input type="hidden" name="authenticity_token" value={token} />

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[first_name]"
              id="data_first_name"
              label="First Name"
              required
              maxLength="25"
            />
          </div>
          <div className="form-item">
            <Input
              type="text"
              name="data[middle_name]"
              id="data_middle_name"
              label="Middle Name"
              maxLength="25"
            />
          </div>
          <div className="form-item">
            <Input
              type="text"
              name="data[last_name]"
              id="data_last_name"
              label="Last Name"
              required
              maxLength="25"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-item">
            <Input
              type="select"
              name="data[changed_name]"
              id="data_changed_name"
              label="Has your name changed since your attendance at AIMS?"
              placeholder=""
              options={[
                {
                  id: "changed_name_yes",
                  label: "Yes",
                  value: "yes",
                },
                {
                  id: "changed_name_no",
                  label: "No",
                  value: "no",
                },
              ]}
              required
              onChange={(event) => {
                updateData("changed_name", event.target.value);
              }}
            />
          </div>
          {formData.changed_name === "yes" && (
            <div className="form-item">
              <Input
                type="text"
                name="data[old_name]"
                id="data_old_name"
                label="Name at Time of Attendance"
                required
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[birth_date]"
              id="data_birth_date"
              label="Date of Birth"
              placeholder="MM/DD/YYYY"
              hint="Expected format: MM/DD/YYYY"
              maxLength="10"
              pattern="\d{2}\/\d{2}/\d{4}"
              required
            />
          </div>
          <div className="form-item">
            <Input
              type="email"
              name="data[email]"
              id="data_email"
              label="Preferred Email Address"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[program]"
              id="data_program"
              label="Program of Study"
              required
            />
          </div>
          <div className="form-item">
            <Input
              type="restirctiveText"
              name="data[graduation_year]"
              id="data_graduation_year"
              label="Graduation Year"
              maxLength="4"
              hint="Expected format: YYYY"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[linkedin]"
              id="data_linkedin"
              label="Your LinkedIn URL"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="radio-group"
              name="data[newsletter]"
              label="Would you like to join the AIMS alumni email list and newsletter?"
              options={[
                { id: "newsletter_yes", label: "Yes", value: "Yes" },
                { id: "newsletter_no", label: "No", value: "No" },
              ]}
              required
              onChange={(value) => {
                updateData("newsletter", value);
              }}
            />
          </div>
        </div>

        <div className="center">
          <button
            type="submit"
            className="btn-primary lg"
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AlumniForm;
