import React from "react";
import PropTypes from "prop-types";
import Layout from "./frontend/Layout";
import LandingLayout from "./frontend/LandingLayout";
import LIBRARY from "./widgets";
import { HostProvider, AuthTokenContext } from "./providers";

const pickLayout = ({ layout }) => {
  if (layout === "landing") {
    return LandingLayout;
  }

  return Layout;
};

const PageRender = ({ page, page_context, host_url, auth_token }) => {
  const LayoutComponent = pickLayout(page);

  return (
    <HostProvider url={host_url}>
      <AuthTokenContext.Provider value={auth_token}>
        <LayoutComponent page={page} pageContext={page_context}>
          {page.widgets.map((widget, idx) =>
            React.createElement(
              LIBRARY[widget.component],
              Object.assign({}, { key: idx }, widget.settings)
            )
          )}
        </LayoutComponent>
      </AuthTokenContext.Provider>
    </HostProvider>
  );
};

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
