import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import StarsForm from "../frontend/Forms/SimplifiedStarsForm";
import { AuthTokenContext } from "../providers";
import AimsPrograms from "../frontend/Forms/AimsPrograms";
import AimsProgramsInput from "../inputs/AimsProgramsInput";

const SimplifiedStarsWidget = ({ list = [], containerSettings }) => {
  const token = React.useContext(AuthTokenContext);
  const programs = AimsPrograms.selectPrograms(list);

  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-4">
            <StarsForm token={token} programs={programs} />
          </div>
        </div>
      </div>
    </Container>
  );
};

SimplifiedStarsWidget.title = "Stars Form (simplified)";
SimplifiedStarsWidget.category = "Forms";

SimplifiedStarsWidget.defaults = {
  containerSettings: {},
  list: AimsProgramsInput.defaults,
};

SimplifiedStarsWidget.adminFields = {
  list: { type: "custom", component: AimsProgramsInput },
};

export default SimplifiedStarsWidget;
