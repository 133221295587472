import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Img from "../frontend/Image";
import ButtonsInput from "../inputs/ButtonsInput";
import Button from "../frontend/Button";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";

function Content({ align, title, description, buttons }) {
  return (
    <div className="content">
      <h1 className="title">{title}</h1>

      {description !== "<p><br></p>" && (
        <HtmlLinksDecoration
          className={`description ${align}`}
          text={description}
        />
      )}

      <div>
        <div className="buttons">
          {buttons.map((item) => (
            <Button key={item.id} settings={item.button} />
          ))}
        </div>
      </div>
    </div>
  );
}

const PageHeroWidget = ({
  background,
  desktop_height = "medium",
  background_mobile,
  align,
  title,
  description,
  buttons,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="page-hero">
      <Img
        src={background}
        variant="lead"
        className={`background ${desktop_height}`}
      />

      <Img
        src={background_mobile}
        variant="image"
        className="background-mobile"
      />

      <Content
        align={align}
        title={title}
        description={description}
        buttons={buttons}
      />
    </div>
  </Container>
);

PageHeroWidget.title = "Page Hero";
PageHeroWidget.category = "Media";
PageHeroWidget.primaryField = "title";

PageHeroWidget.defaults = {
  background: "https://placehold.it/1920x1080",
  desktop_height: "medium",
  align: "text-center",
  title: "Page Hero",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas velit debitis doloremque et dolor ad in, at modi nulla, dolores obcaecati. Labore maxime doloribus sapiente, ipsam blanditiis corrupti aspernatur cumque?</p>",
  buttons: ButtonsInput.defaults,
  containerSettings: {},
};

PageHeroWidget.adminFields = {
  background: { type: "custom", component: ImagePicker },
  desktop_height: {
    type: "select",
    options: [
      ["short", "Short"],
      ["medium", "Medium"],
      ["long", "Long"],
    ],
  },
  background_mobile: { type: "custom", component: ImagePicker },
  align: {
    type: "select",
    options: [
      ["text-center", "Center"],
      ["text-left", "Left"],
    ],
  },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  buttons: { type: "custom", component: ButtonsInput },
};

export default PageHeroWidget;
