import React from "react";
import Input from "../../frontend/UncontrolledInput";
import { AuthTokenContext } from "../../providers";
import AimsPrograms from "../../frontend/Forms/AimsPrograms";

const ProgramForm = ({
  form_title,
  form_disclaimer,
  form_button,
  form_checkbox_1,
  form_checkbox_2,
  form_checkbox_3,
  form_checkbox_4,
  form_programs,
}) => {
  const token = React.useContext(AuthTokenContext);
  const [submitting, setSubmitting] = React.useState(false);
  const programs = AimsPrograms.selectPrograms(form_programs);

  return (
    <div className="program-form">
      <h2 className="form-title">{form_title}</h2>

      <form
        method="POST"
        action="/programs"
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <Input type="hidden" name="authenticity_token" value={token} />

        <div className="form-row">
          {form_checkbox_1 && (
            <div className="form-item">
              <Input
                type="checkbox"
                name="stars[checkbox_1]"
                label={form_checkbox_1}
                defaultValue={form_checkbox_1}
                id="stars_program_checkbox_1"
              />
            </div>
          )}
          {form_checkbox_2 && (
            <div className="form-item">
              <Input
                type="checkbox"
                name="stars[checkbox_2]"
                label={form_checkbox_2}
                defaultValue={form_checkbox_2}
                id="stars_program_checkbox_2"
              />
            </div>
          )}
        </div>

        <div className="form-row">
          {form_checkbox_3 && (
            <div className="form-item">
              <Input
                type="checkbox"
                name="stars[checkbox_3]"
                label={form_checkbox_3}
                defaultValue={form_checkbox_3}
                id="stars_program_checkbox_3"
              />
            </div>
          )}

          {form_checkbox_4 && (
            <div className="form-item">
              <Input
                type="checkbox"
                name="stars[checkbox_4]"
                label={form_checkbox_4}
                defaultValue={form_checkbox_4}
                id="stars_program_checkbox_4"
              />
            </div>
          )}
        </div>

        <Input
          type="select"
          name="stars[program_1]"
          label="Program of interest"
          placeholder=""
          id="stars_program_1"
          options={programs}
          required
        />

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="stars[first_name]"
              id="stars_first_name"
              label="First Name"
              required
              maxLength="12"
            />
          </div>

          <div className="form-item">
            <Input
              type="text"
              name="stars[last_name]"
              id="stars_last_name"
              label="Last Name"
              required
              maxLength="25"
            />
          </div>
        </div>

        <Input
          type="email"
          name="stars[email]"
          id="stars_email"
          label="Email"
          required
        />

        <Input
          type="restirctiveText"
          name="stars[phone]"
          id="stars_phone"
          label="Phone"
          required
          maxLength="10"
          allowedCharacters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
          placeholder="Please enter a 10 digit phone number"
        />

        <Input
          type="restirctiveText"
          name="stars[zip]"
          id="zip"
          label="Zip"
          required
          maxLength="5"
          allowedCharacters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
          placeholder="Please enter a 5 digit ZIP code"
        />
        {form_disclaimer !== "<p><br></p>" && (
          <div className="spacing-bottom-sm">
            <Input
              type="checkbox"
              name="stars[terms]"
              defaultValue="accept"
              required
              labelClass="checkbox-label-grid"
              label={() => (
                <div
                  className="disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: form_disclaimer,
                  }}
                />
              )}
            />
          </div>
        )}

        <div className="center">
          <button
            type="submit"
            className="btn-primary lg"
            disabled={submitting}
          >
            {form_button}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProgramForm;
