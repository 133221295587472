import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const ImageTextWidget = ({
  align = "vertical-center",
  title,
  description,
  image,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className={`image-text ${align}`}>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <Img src={image} variant="image" loading="lazy" alt={title} />
          </div>

          <div className="col-8">
            <div className="content-wrapper">
              <div className="content">
                {title && <h3>{title}</h3>}

                <HtmlLinksDecoration text={description} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

ImageTextWidget.title = "Image and text";
ImageTextWidget.category = "Media";
ImageTextWidget.primaryField = "title";

ImageTextWidget.defaults = {
  align: "vertical-center",
  title: "Example reference title",
  description:
    "<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus alias officiis magni eaque natus est quae doloremque dignissimos at asperiores! Dignissimos, eveniet eum rerum quasi corporis qui delectus alias saepe.</p>",
  image: "https://placehold.it/800x600",
  containerSettings: {},
};

ImageTextWidget.adminFields = {
  align: {
    type: "select",
    options: [
      ["vertical-center", "Vertical center"],
      ["vertical-top", "Vertical top"],
    ],
  },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  image: { type: "custom", component: ImagePicker },
};

export default ImageTextWidget;
