const DeliveryMethod = [
  { id: 1, value: "Pick up on campus", label: "Pick up on campus" },
  {
    id: 2,
    value: "Mail",
    label: "Mail",
  },
];

export default DeliveryMethod;
