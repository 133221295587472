import React from "react";
import { ButtonSettings, List } from "@raketa-cms/raketa-cms";

const TEMPLATE = { button: ButtonSettings.defaults };

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ButtonSettings
      label="Link"
      onChange={(value) => onChangeItem("button", value)}
      value={settings.button}
    />
  </div>
);

function ButtonsInput({ label, value, onChange }) {
  return (
    <List
      label={label || "Buttons"}
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={value}
      template={TEMPLATE}
      primaryField="button.label"
      onChangeList={(_key, list) => onChange(list)}
    />
  );
}

ButtonsInput.defaults = [
  { ...TEMPLATE, id: 1 },
  { ...TEMPLATE, id: 2 },
  { ...TEMPLATE, id: 3 },
];

export default ButtonsInput;
