import React from "react";
import MiniFooter from "./MiniFooter";
import GlobalNotice from "./GlobalNotice";
import showGlobalNotice from "./showGlobalNotice";
import LayoutModals from "./LayoutModals";

export default ({ page, pageContext, children }) => (
  <LayoutModals>
    {showGlobalNotice(page) && <GlobalNotice settings={pageContext.settings} />}

    <div>{children}</div>

    <div className="footer">
      <MiniFooter context={pageContext} />
    </div>
  </LayoutModals>
);
