import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../../frontend/UncontrolledInput";
import { AuthTokenContext } from "../../providers";

const AIMS_SOURCES = [
  "Web Search",
  "Referral",
  "Digital/Streaming Ad",
  "Google Ad",
  "Facebook Ad",
  "Instagram Ad",
  "YouTube Ad",
  "LinkedIn Ad",
  "Twitter Ad",
  "Facebook Page",
  "Instagram Page",
  "Twitter Page",
  "YouTube Page",
  "LinkedIn Page",
  "TikTok",
  "NJ Training Explorer",
  "NJ OSHE",
  "CAAHEP",
  "Career Fair",
  "TV",
  "Radio",
  "Newspaper",
  "Magazine",
  "Billboard",
  "Drove By",
  "Other",
];

const QForm = ({ programs, sessionTypes, skipSchedule }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState("");
  const [formData, setFormData] = React.useState({});
  const token = React.useContext(AuthTokenContext);

  const safeSessionTypes = sessionTypes.map((st) => ({
    id: st.id,
    label: st.title,
    value: st.title,
  }));

  let sessionUrl = null;

  if (formData.session_type) {
    const st = sessionTypes.find((st) => st.title == formData.session_type);

    if (st) {
      sessionUrl = st.url;
    }
  }

  const updateData = (key, value) => {
    setFormData((current) => {
      current[key] = value;

      return { ...current };
    });
  };

  return (
    <div className="q-form">
      <form
        method="POST"
        action="/questionarres"
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <Input type="hidden" name="authenticity_token" value={token} />

        {safeSessionTypes.length > 0 && (
          <div className="form-row">
            <div className="form-item">
              <Input
                type="select"
                name="data[session_type]"
                label="Which info session did you register for?"
                placeholder=""
                id="data_session_type"
                options={safeSessionTypes}
                required
                onChange={(event) => {
                  updateData("session_type", event.target.value);
                }}
              />
            </div>

            {sessionUrl && (
              <Input
                type="hidden"
                name="data[session_url]"
                value={sessionUrl}
              />
            )}
          </div>
        )}

        <h5 className="title spacing-top-sm">Biographical Information</h5>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[first_name]"
              id="data_first_name"
              label="First Name"
              required
              maxLength="25"
            />
          </div>
          <div className="form-item">
            <Input
              type="text"
              name="data[last_name]"
              id="data_last_name"
              label="Last Name"
              required
              maxLength="25"
            />
          </div>

          <div className="form-item">
            <Input
              type="text"
              name="data[date_of_birth]"
              id="data_date_of_birth"
              label="Date of Birth"
              placeholder="MM/DD/YYYY"
              hint="Expected format: MM/DD/YYYY"
              maxLength="10"
              pattern="\d{2}\/\d{2}/\d{4}"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="restirctiveText"
              name="data[phone]"
              id="data_phone"
              label="Phone"
              required
              maxLength="10"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              placeholder="Please enter a 10 digit phone number"
            />
          </div>

          <div className="form-item">
            <Input
              type="email"
              name="data[email]"
              id="data_email"
              label="Email"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="select"
              name="data[citizenship_status]"
              label="Citizenship Status"
              placeholder=""
              id="data_citizenship_status"
              options={[
                {
                  id: "1-citizenship",
                  label: "US Citizen",
                  value: "US Citizen",
                },
                {
                  id: "2-citizenship",
                  label: "Permanent Resident",
                  value: "Permanent Resident",
                },
                {
                  id: "3-citizenship",
                  label: "Noncitizen",
                  value: "Noncitizen",
                },
              ]}
              required
              onChange={(event) => {
                updateData("citizenship_status", event.target.value);
              }}
            />
          </div>

          {formData.citizenship_status === "Noncitizen" && (
            <div className="form-item">
              <Input
                type="text"
                name="data[citizenship_details]"
                id="data_citizenship_details"
                label="Please explain visa or immigration status."
                required
              />
            </div>
          )}
        </div>

        <div className="form-row full-width">
          <Input
            type="text"
            name="data[address]"
            id="data_address"
            label="Street address"
            required
          />
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[city]"
              id="data_city"
              label="City"
              required
              maxLength="40"
            />
          </div>

          <div className="form-item">
            <Input
              type="text"
              name="data[state]"
              id="data_state"
              label="State"
              required
              maxLength="40"
            />
          </div>

          <div className="form-item">
            <Input
              type="restirctiveText"
              name="data[zip]"
              id="data_zip"
              label="Zip"
              required
              maxLength="5"
              allowedCharacters={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ]}
              placeholder="Please enter a 5 digit ZIP code"
            />
          </div>
        </div>

        <h5 className="title spacing-top-sm">Academic Program Information</h5>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="select"
              name="data[program]"
              label="Program of interest"
              placeholder=""
              id="data_program"
              options={programs}
              required
            />
          </div>

          {skipSchedule !== "yes" && (
            <div className="form-item">
              <Input
                type="select"
                name="data[preferred_schedule]"
                label="Preferred Schedule"
                placeholder=""
                id="data_preferred_schedule"
                options={[
                  { id: "1-preferred_schedule", value: "Day", label: "Day" },
                  {
                    id: "2-preferred_schedule",
                    value: "Evening",
                    label: "Evening",
                  },
                  {
                    id: "3-preferred_schedule",
                    value: "Weekend",
                    label: "Weekend",
                  },
                  {
                    id: "4-preferred_schedule",
                    value: "Flexible",
                    label: "Flexible",
                  },
                ]}
                required
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[start_at]"
              id="data_start_at"
              label="When would you like to start?"
              required
              maxLength="40"
            />
          </div>
        </div>

        <h5 className="title spacing-top-sm">Funding Your Education</h5>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="checkbox-group"
              name="data[funding]"
              label="How will you fund your education?"
              required
              options={[
                {
                  id: "1-data_funding",
                  value: "Self payment",
                  label: "Self payment",
                },
                {
                  id: "2-data_funding",
                  value: "Financial aid",
                  label: "Financial aid",
                },
                {
                  id: "3-data_funding",
                  value: "Parents",
                  label: "Parents",
                },
                {
                  id: "4-data_funding",
                  value: "Employer reimbursement",
                  label: "Employer reimbursement",
                },
                {
                  id: "5-data_funding",
                  value: "Unemployment grant",
                  label: "Unemployment grant",
                },
                {
                  id: "6-data_funding",
                  value: "Other",
                  label: "Other",
                },
              ]}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="radio-group"
              name="data[unemployment_benefits]"
              label="Are you eligible for unemployment benefits?"
              options={[
                { id: "1-unemployment_benefits", label: "Yes", value: "Yes" },
                { id: "2-unemployment_benefits", label: "No", value: "No" },
                {
                  id: "3-unemployment_benefits",
                  label: "Unknown",
                  value: "Unknown",
                },
              ]}
              required
              onChange={(value) => {
                updateData("unemployment_benefits", value);
              }}
            />
          </div>
        </div>

        {formData.unemployment_benefits === "Yes" && (
          <>
            <strong>Please provide the information below</strong>
            <div className="form-row">
              <div className="form-item">
                <Input
                  type="text"
                  name="data[county]"
                  id="data_county"
                  label="County"
                  required
                />
              </div>

              <div className="form-item">
                <Input
                  type="text"
                  name="data[counselor_name]"
                  id="data_counselor_name"
                  label="Counselor Name"
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-item">
                <Input
                  type="email"
                  name="data[counselor_email]"
                  id="data_counselor_email"
                  label="Counselor Email"
                  required
                />
              </div>

              <div className="form-item">
                <Input
                  type="restirctiveText"
                  name="data[counselor_phone]"
                  id="data_counselor_phone"
                  label="Counselor Phone"
                  required
                  maxLength="10"
                  allowedCharacters={[
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ]}
                  placeholder="Please enter a 10 digit phone number"
                />
              </div>
            </div>
          </>
        )}

        <h5 className="title spacing-top-sm">
          Education and Employment History
        </h5>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[school_graduation_year]"
              id="data_school_graduation_year"
              label="What year did you graduate high school?"
              required
            />
          </div>

          <div className="form-item">
            <Input
              type="select"
              name="data[education_level]"
              label="What is your highest level of education?"
              placeholder=""
              id="data_education_level"
              options={[
                {
                  id: "1-education_level",
                  label: "High School",
                  value: "High School",
                },
                {
                  id: "2-education_level",
                  label: "Some College",
                  value: "Some College",
                },
                {
                  id: "3-education_level",
                  label: "Associate Degree",
                  value: "Associate Degree",
                },
                {
                  id: "4-education_level",
                  label: "Bachelor's or higher",
                  value: "Bachelor's or higher",
                },
              ]}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="text"
              name="data[field_of_study]"
              id="data_field_of_study"
              label="What was your field of study?"
            />
          </div>

          <div className="form-item">
            <Input
              type="text"
              name="data[college_credits]"
              id="data_college_credits"
              label="How many college credits have you earned?"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <Input
            type="radio-group"
            name="data[currently_attending_school]"
            label="Are you currently attending school at another institution?"
            id="data_currently_attending_school"
            options={[
              {
                id: "1-currently_attending_school",
                label: "Yes",
                value: "Yes",
              },
              {
                id: "2-currently_attending_school",
                label: "No",
                value: "No",
              },
            ]}
            required
          />
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="radio-group"
              name="data[foreign_graduate]"
              label="Are you a foreign graduate?"
              options={[
                { id: "1-data_foreign_graduate", label: "Yes", value: "Yes" },
                { id: "2-data_foreign_graduate", label: "No", value: "No" },
              ]}
              required
              onChange={(value) => {
                updateData("foreign_graduate", value);
              }}
            />
          </div>
        </div>

        {formData.foreign_graduate === "Yes" && (
          <div className="form-row">
            <div className="form-item">
              <Input
                type="radio-group"
                name="data[academic_credential_evaluation]"
                label="Has an academic credential evaluation company evaluated your diploma or degree?"
                options={[
                  {
                    id: "1-data_academic_credential_evaluation",
                    label: "Yes",
                    value: "Yes",
                  },
                  {
                    id: "2-data_academic_credential_evaluation",
                    label: "No",
                    value: "No",
                  },
                ]}
                required
                onChange={(value) => {
                  updateData("academic_credential_evaluation", value);
                }}
              />
            </div>

            {formData.academic_credential_evaluation === "Yes" && (
              <div className="form-item">
                <Input
                  type="text"
                  name="data[academic_evaluation_company]"
                  id="data_academic_evaluation_company"
                  label="Evaluation Company Name"
                  required
                />
              </div>
            )}
          </div>
        )}

        <div className="form-row">
          <div className="form-item">
            <Input
              type="radio-group"
              name="data[healthcare_experience]"
              label="Do you have prior healthcare education or work experience?"
              options={[
                {
                  id: "1-data_healthcare_experience",
                  label: "Yes",
                  value: "Yes",
                },
                {
                  id: "2-data_healthcare_experience",
                  label: "No",
                  value: "No",
                },
              ]}
              required
              onChange={(value) => {
                updateData("healthcare_experience", value);
              }}
            />
          </div>

          {formData.healthcare_experience === "Yes" && (
            <div className="form-item">
              <Input
                type="text"
                name="data[healthcare_experience_details]"
                id="data_healthcare_experience_details"
                label="Please explain"
                required
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="radio-group"
              name="data[currently_employed]"
              label="Are you currently employed?"
              options={[
                { id: "1-data_currently_employed", label: "Yes", value: "Yes" },
                { id: "2-data_currently_employed", label: "No", value: "No" },
              ]}
              required
              onChange={(value) => {
                updateData("currently_employed", value);
              }}
            />
          </div>

          {formData.currently_employed === "Yes" && (
            <div className="form-item">
              <Input
                type="radio-group"
                name="data[employment_type]"
                label="Employment type"
                options={[
                  {
                    id: "1-data_employment_type",
                    label: "Full Time",
                    value: "Full Time",
                  },
                  {
                    id: "2-data_employment_type",
                    label: "Part Time",
                    value: "Part Time",
                  },
                ]}
                required
              />
            </div>
          )}
        </div>

        <h5 className="title spacing-top-sm">Additional Information</h5>

        <div className="form-row">
          <div className="form-item">
            <Input
              type="select"
              name="data[found_aims]"
              label="How did you first learn about AIMS Education?"
              id="data_found_aims"
              placeholder=""
              options={AIMS_SOURCES.map((as, idx) => ({
                id: idx,
                label: as,
                value: as,
              }))}
              required
              onChange={(event) => {
                updateData("found_aims", event.target.value);
              }}
            />
          </div>

          {formData.found_aims === "Other" && (
            <div className="form-item">
              <Input
                type="text"
                name="data[found_aims_details]"
                id="data_found_aims_details"
                label="Please explain."
                required
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-item">
            <div className="form-group">
              <label className="control-label">Text message opt-in</label>
              <div className="help-block">
                Please check off the box below if you would like to communicate
                with us via text message. If you have already opted in, then you
                can ignore this field.
              </div>
            </div>

            <Input
              type="checkbox"
              name="data[newsletter]"
              defaultValue="Yes"
              labelClass="checkbox-label-grid"
              label="Text message opt-in"
            />
          </div>
        </div>

        <div className="center">
          <ReCAPTCHA
            sitekey="6LfOXSUcAAAAAHgluB7FB5JrlEDkeWj9O7lNHULc"
            onChange={(value) => setRecaptcha(value)}
          />
        </div>

        <Input type="hidden" name="g-recaptcha-response" value={recaptcha} />

        <div className="center">
          <button
            type="submit"
            className="btn-primary lg"
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default QForm;
