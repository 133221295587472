import React from "react";
import { Container, ButtonSettings } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const AccentWidget = ({
  title,
  description,
  button,
  image,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="accent">
      <div className="container">
        <div className="accent-inner">
          <div className="row">
            <div className="col-6 content-wrapper">
              <div className="content">
                <h3 className="title">{title}</h3>

                <HtmlLinksDecoration
                  className="description"
                  text={description}
                />
                {button.label && <Button settings={button} />}
              </div>
            </div>

            <div className="col-6">
              <Img src={image} variant="lead" loading="lazy" alt={title} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

AccentWidget.title = "Accent";
AccentWidget.category = "Media";
AccentWidget.primaryField = "title";

AccentWidget.defaults = {
  title: "Accent",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, placeat veniam deserunt iste porro facilis, laborum amet quos facere quod repellendus aut. Necessitatibus harum corrupti veniam dolore dolores totam sunt!</p>",
  button: ButtonSettings.defaults,
  image: "https://placehold.it/1920x1080",
  containerSettings: {},
};

AccentWidget.adminFields = {
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button: { type: "button" },
  image: { type: "custom", component: ImagePicker },
};

export default AccentWidget;
