import React from "react";
import {
  Container,
  List,
  TextInput,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Swiper from "react-id-swiper";
import Img from "../frontend/Image";

const Logo = ({
  container_width,
  container_min_width,
  container_height,
  width,
  image,
}) => (
  <div
    className="logo"
    style={{
      width: container_width,
      height: container_height,
      minWidth: container_min_width,
    }}
  >
    <Img src={image} variant="image" className="image" style={{ width }} />
  </div>
);

const LinkLogo = ({
  container_width,
  container_min_width,
  container_height,
  width,
  image,
  linkSettings,
}) => {
  const { label, link, id, target, className, rel } = linkSettings;

  return (
    <a
      href={link}
      id={id ? id : ""}
      target={target}
      rel={rel}
      title={label}
      className={`logo ${className}`}
      style={{
        width: container_width,
        height: container_height,
        minWidth: container_min_width,
      }}
    >
      <Img src={image} variant="image" className="image" style={{ width }} />
    </a>
  );
};

const Item = ({
  container_width,
  container_min_width,
  container_height,
  width,
  link,
  image,
}) => {
  if (link.link) {
    return (
      <LinkLogo
        container_width={container_width}
        container_height={container_height}
        container_min_width={container_min_width}
        width={width}
        image={image}
        linkSettings={link}
      />
    );
  }

  return (
    <Logo
      container_width={container_width}
      container_height={container_height}
      container_min_width={container_min_width}
      width={width}
      image={image}
    />
  );
};

const AdvancedLogosWidget = ({
  container_width,
  container_min_width,
  container_height,
  list,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="advanced-logos">
      <div className="container">
        <div className="logos-inner">
          {list.map((item, idx) => (
            <Item
              key={item.id + idx}
              container_min_width={container_min_width}
              container_width={container_width}
              container_height={container_height}
              {...item}
            />
          ))}
        </div>

        <div className="logos-mobile">
          <Swiper
            rebuildOnUpdate
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={30}
            paginationClickable
          >
            {list.map((item, idx) => (
              <div key={item.id + idx} className="logo">
                <Item
                  key={item.id}
                  container_min_width={container_min_width}
                  container_width={container_width}
                  container_height={container_height}
                  {...item}
                />
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  </Container>
);

AdvancedLogosWidget.title = "Logos (advanced)";
AdvancedLogosWidget.category = "Media";

AdvancedLogosWidget.defaults = {
  container_width: "25%",
  container_height: "150px",
  container_min_width: "250px",
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      image: "https://placehold.it/400x300",
      width: "50%",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      image: "https://placehold.it/300x300",
      width: "50%",
    },
    {
      id: 3,
      link: LinkSettings.defaults,
      image: "https://placehold.it/400x400",
      width: "50%",
    },
    {
      id: 4,
      link: LinkSettings.defaults,
      image: "https://placehold.it/400x400",
      width: "50%",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Width"
      onChange={(value) => onChangeItem("width", value)}
      value={settings.width}
    />
  </div>
);

AdvancedLogosWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Container Width"
      onChange={(value) => onChange("container_width", value)}
      value={settings.container_width}
    />

    <TextInput
      label="Container Minimum Width"
      onChange={(value) => onChange("container_min_width", value)}
      value={settings.container_min_width}
    />

    <TextInput
      label="Container Height"
      onChange={(value) => onChange("container_height", value)}
      value={settings.container_height}
    />

    <List
      label="Logos"
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        link: LinkSettings.defaults,
        image: "https://placehold.it/400x300",
        width: "150px",
      }}
      primaryField="link.label"
      onChangeList={onChange}
    />
  </div>
);

export default AdvancedLogosWidget;
