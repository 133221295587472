import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Link from "../frontend/Link";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const Item = ({ title, link, image, description }) => (
  <div className="square-feed">
    <Link settings={link}>
      <Img
        src={image}
        variant="square"
        className="image"
        loading="lazy"
        alt={link.label}
      />
    </Link>

    <h4 className="title">
      <Link settings={link}>{title}</Link>
    </h4>

    {description && (
      <HtmlLinksDecoration className="description" text={description} />
    )}
  </div>
);

const SquareFeedWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="square-feeds">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={variant}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

SquareFeedWidget.title = "Square feed";
SquareFeedWidget.category = "Media";

SquareFeedWidget.defaults = {
  variant: "col-3",
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      image: "https://placehold.it/540x540",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      image: "https://placehold.it/540x540",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 3,
      link: LinkSettings.defaults,
      image: "https://placehold.it/540x540",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 4,
      link: LinkSettings.defaults,
      image: "https://placehold.it/540x540",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

SquareFeedWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-3", "4 columns"],
        ["col-2", "6 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        link: LinkSettings.defaults,
        image: "https://placehold.it/540x540",
        title: "Title",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default SquareFeedWidget;
