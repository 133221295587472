import React from 'react';
import { TextInput } from '@raketa-cms/raketa-cms';

const roundBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundSize: '40%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  border: 'none',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  color: '#fff',
}

const delBtn = {
  backgroundImage: 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT54LTI8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJ4LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTIsMCBMMCwxMiIgaWQ9IlBhdGgiPjwvcGF0aD4gICAgICAgICAgICA8cGF0aCBkPSJNMCwwIEwxMiwxMiIgaWQ9IlBhdGgiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==)',
  backgroundColor: '#cc3333',
  position: 'relative',
  top: '-8px',
  ...roundBtn,
}

const addBtn = {
  backgroundImage: 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5wbHVzPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0icGx1cyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIDEuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik03LDAgTDcsMTQiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICAgICAgPHBhdGggZD0iTTAsNyBMMTQsNyIgaWQ9IlBhdGgiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==)',
  backgroundColor: '#21ad2e',
  position: 'relative',
  top: '-8px',
  marginLeft: '0.5em',
  ...roundBtn,
}

const addRowBtn = {
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  lineHeight: '1.5',
  fontWeight: 'normal',
  backgroundColor: 'transparent',
  border: '0',
  overflow: 'visible',
  cursor: 'pointer',
  color: '#fff',
  backgroundColor: 'rgb(0, 187, 102)',
  display: 'inline-block',
  fontSize: '12px',
  padding: '4.8px 11.2px',
  marginRight: '8px',
  borderRadius: '3px',
}

class TableBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table: props.tableContent.table,
      columnClasses: props.tableContent.columnClasses,
    };
  }

  handleAddCol = () => {
    const { table, columnClasses } = this.state;
    const newTable = table.map(row => [...row, '']);
    const newColumnClass = [...columnClasses, ''];

    this.setState({ table: newTable, columnClasses: newColumnClass }, () => this.notifyChange());
  }

  handleRemoveCol = (cidx) => {
    const { table } = this.state;
    const newTable = table.map((row) => [
      ...row.slice(0, cidx),
      ...row.slice(cidx + 1),
    ]);

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleAddRow = () => {
    const { table } = this.state;
    const firstRow = table[0];

    const newRow = Array.from(Array(firstRow.length).keys()).map(_ => '');

    const newTable = [
      ...table,
      newRow
    ];

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleRemoveRow = (ridx) => {
    const { table } = this.state;
    const newTable = [
      ...table.slice(0, ridx),
      ...table.slice(ridx + 1),
    ];

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleChangeCell(value, ridx, cidx) {
    const { table } = this.state;
    const newTable = table.map((row, rridx) => {
      if (ridx !== rridx) return row;

      return [
        ...row.slice(0, cidx),
        value,
        ...row.slice(cidx + 1),
      ];
    });

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleColumnClassChange(value, idx) {
    const { columnClasses } = this.state;
    const newColumnClass = [
      ...columnClasses.slice(0, idx),
      value,
      ...columnClasses.slice(idx + 1),
    ];

    this.setState({ columnClasses: newColumnClass }, () => this.notifyChange());
  }

  notifyChange() {
    const { table, columnClasses } = this.state;

    if (this.props.onChange) this.props.onChange({ table, columnClasses });
  }

  render() {
    const { table, columnClasses } = this.state;
    const firstRow = table[0];

    return (
      <div className="table-builder">
        <table>
          <tbody>
            <tr>
              {table[0].map((_, idx) =>
                <td key={idx}>
                  <TextInput
                    value={columnClasses[idx]}
                    placeholder="Column class..."
                    onChange={value => this.handleColumnClassChange(value, idx)}
                  />
                </td>
              )}

              <td />
            </tr>

            {table.map((row, ridx) => (
              <tr key={ridx} className={ridx === 0 ? 'body-separator' : ''}>
                {row.map((col, cidx) => (
                  <td key={cidx}>
                    <TextInput
                      value={col}
                      onChange={value => this.handleChangeCell(value, ridx, cidx)}
                    />
                  </td>
                ))}
                <td style={{ width: '50px' }}>
                  {(ridx === 0) &&
                    <button type="button" onClick={this.handleAddCol} style={addBtn} />
                  }

                  {(ridx > 0) &&
                    <button type="button" onClick={() => this.handleRemoveRow(ridx)} style={delBtn} />
                  }
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {firstRow.map((_, cidx) => (
                <td key={cidx}>
                  <button type="button" onClick={() => this.handleRemoveCol(cidx)} style={delBtn} />
                </td>
              ))}
              <td>&nbsp;</td>
            </tr>
          </tfoot>
        </table>

        <button type="button" onClick={this.handleAddRow} style={addRowBtn}>Add row</button>
      </div>
    );
  }
}

TableBuilder.defaultProps = {
  value: [['Col 1', 'Col 2', 'Col 3']],
  columnClasses: ['', '', ''],
};

export default TableBuilder;
