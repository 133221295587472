import React from "react";
import { Container, ButtonSettings, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";
import HtmlLinksDecoration from "../frontend/HtmlLinksDecoration";
import Img from "../frontend/Image";

const LeadImageWidget = ({
  desktop_height,
  background,
  background_mobile,
  overlay,
  overlay_theme,
  title,
  description,
  button_1,
  button_2,
  containerSettings,
}) => {
  const mobileImage = background_mobile
    ? imageSrc(background_mobile, "image")
    : imageSrc(background, "image");
  return (
    <Container settings={containerSettings}>
      <div
        className={`lead-image ${overlay_theme} ${desktop_height || "medium"}`}
      >
        <div className="mobile-background relative">
          <Img src={mobileImage} variant="image" className="image" />
          <div className="desktop-background">
            <Img
              src={imageSrc(background, "lead")}
              variant="image"
              className="image"
            />
            <div className="overlay" style={{ opacity: overlay }} />

            <div className="container">
              <div className="hero-inner">
                <div className="content">
                  <div className="row">
                    <div className="shift-2">
                      <h1 className="title">{title}</h1>

                      {description !== "<p><br></p>" && (
                        <HtmlLinksDecoration
                          className="description"
                          text={description}
                        />
                      )}

                      {(button_1.label || button_2.label) && (
                        <div className="button-wrapper">
                          {button_1.label && (
                            <Button className="lg" settings={button_1} />
                          )}

                          {button_2.label && (
                            <Button className="lg" settings={button_2} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

LeadImageWidget.title = "Lead image";
LeadImageWidget.category = "Media";
LeadImageWidget.primaryField = "title";

LeadImageWidget.defaults = {
  background: "https://placehold.it/1920x1080",
  background_mobile: "https://placehold.it/540x540",
  overlay: "0.5",
  overlay_theme: "overlay-brand",
  desktop_height: "medium",
  title: "Lead Image",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas velit debitis doloremque et dolor ad in, at modi nulla, dolores obcaecati. Labore maxime doloribus sapiente, ipsam blanditiis corrupti aspernatur cumque?</p>",
  button_1: ButtonSettings.defaults,
  button_2: ButtonSettings.defaults,
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  background: { type: "custom", component: ImagePicker },
  background_mobile: { type: "custom", component: ImagePicker },
  overlay: {
    type: "select",
    options: [
      ["0", "None"],
      ["0.1", "10%"],
      ["0.2", "20%"],
      ["0.3", "30%"],
      ["0.4", "40%"],
      ["0.5", "50%"],
      ["0.6", "60%"],
      ["0.7", "70%"],
      ["0.8", "80%"],
      ["0.9", "90%"],
      ["1", "100%"],
    ],
  },
  overlay_theme: {
    type: "select",
    options: [
      ["overlay-black", "Black"],
      ["overlay-brand", "Brand"],
    ],
  },
  desktop_height: {
    type: "select",
    options: [
      ["short", "Short"],
      ["medium", "Medium"],
      ["long", "Long"],
    ],
  },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button_1: { type: "button" },
  button_2: { type: "button" },
};

export default LeadImageWidget;
