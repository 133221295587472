import React from "react";
import { Container, EmbeddedVideo } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Img from "../frontend/Image";

const Modal = ({ children, onClose }) => (
  <div className="sb-modal">
    <button type="button" className="btn-close" onClick={onClose}>
      &times;
    </button>

    <div className="sb-overlay" onClick={onClose} />

    <div className="sb-modal-content">{children}</div>
  </div>
);

const VideoWidget = ({ variant, preview, videoURL, containerSettings }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Container settings={containerSettings}>
        <div className="video">
          <div className="container">
            <div className="row">
              <div className={variant}>
                <button
                  type="button"
                  className="btn-video"
                  onClick={() => setOpen(true)}
                >
                  <Img src={preview} variant="lead" loading="lazy" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {open && (
        <Modal onClose={() => setOpen(false)}>
          <div className="video-content">
            <div className="video-wrapper">
              <EmbeddedVideo videoUrl={videoURL} />
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

VideoWidget.title = "Video";
VideoWidget.category = "Media";
VideoWidget.primaryField = "videoURL";

VideoWidget.defaults = {
  variant: "col-12",
  videoURL: "https://www.youtube.com/watch?v=7Glc2mHLPRM",
  preview: "https://placehold.it/1920x1080",
  containerSettings: {},
};

VideoWidget.defaultProps = {
  variant: "col-12",
};

VideoWidget.adminFields = {
  videoURL: { type: "text" },
  variant: {
    type: "select",
    options: [
      ["col-8", "8 columns"],
      ["col-12", "12 columns"],
      ["shift-1", "Shift 1"],
      ["shift-2", "Shift 2"],
    ],
  },
  preview: { type: "custom", component: ImagePicker },
};

export default VideoWidget;
