import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../UncontrolledInput";
import AimsPrograms, { AdditionalAimsPrograms } from "./AimsPrograms";
import GraduateStatus from "./GraduateStatus";
import FreeTranscript from "./FreeTranscript";
import DeliveryMethod from "./DeliveryMethod";
import DeliverTo from "./DeliverTo";

const ADDRESS_PLACEHOLDER = `Example:

Anywhere College
Attn: Admissions Department
123 Main Street
Anytown, NJ 12345
`;

const TranscriptRequestForm = () => {
  const [recaptcha, setRecaptcha] = React.useState("");

  return (
    <form
      method="POST"
      action="https://forms.raketa.cloud/submit/9Xkc0Skqq0"
      className="transcript-request"
    >
      <div className="form-row">
        <div className="form-item">
          <Input
            type="text"
            name="data[first_name]"
            id="data_first_name"
            label="First Name"
            required
            maxLength="12"
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[last_name]"
            id="data_last_name"
            label="Last Name"
            required
            maxLength="25"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-item">
          <Input
            type="select"
            name="data[program]"
            id="data_program"
            label="Program"
            options={AimsPrograms.concat(AdditionalAimsPrograms)}
            placeholder=""
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="select"
            name="data[graduate_status]"
            id="data_graduate_status"
            label="Graduate Status"
            options={GraduateStatus}
            placeholder=""
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[graduation_date]"
            id="data_graduation_date"
            label="Graduation Date"
            placeholder="MM/YY"
            maxLength="5"
            pattern="\d{2}\/\d{2}"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-item">
          <Input
            type="restirctiveText"
            name="data[phone]"
            id="data_phone"
            label="Phone"
            required
            maxLength="10"
            allowedCharacters={[
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
            ]}
          />
        </div>

        <div className="form-item">
          <Input
            type="email"
            name="data[email]"
            id="data_email"
            label="Email"
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[dob]"
            id="data_dob"
            label="DOB"
            maxLength="8"
            placeholder="MM/DD/YY"
            pattern="\d{2}\/\d{2}\/\d{2}"
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[student_id]"
            id="data_student_id"
            label="Student ID"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-item">
          <Input
            type="text"
            name="data[address]"
            id="data_address"
            label="Address"
            required
            maxLength="75"
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[city]"
            id="data_city"
            label="City"
            required
            maxLength="40"
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[state]"
            id="data_state"
            label="State"
            required
            maxLength="12"
          />
        </div>

        <div className="form-item">
          <Input
            type="restirctiveText"
            name="data[zip]"
            id="data_zip"
            label="Zip"
            required
            maxLength="5"
            allowedCharacters={[
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
            ]}
          />
        </div>
      </div>
      <div className="form-row align">
        <div className="form-item">
          <Input
            type="select"
            name="data[free_transcript]"
            id="data_free_transcript"
            label="Did you receive an official transcript after graduation?"
            options={FreeTranscript}
            placeholder=""
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="number"
            name="data[transcripts_number]"
            id="data_transcripts_number"
            label="How many transcripts are you ordering?"
            step="1"
            min="0"
            max="99"
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="select"
            name="data[delivery_method]"
            id="data_delivery_method"
            label="Delivery method"
            options={DeliveryMethod}
            required
            placeholder=""
          />
        </div>

        <div className="form-item">
          <Input
            type="select"
            name="data[deliver_to]"
            id="data_deliver_to"
            label="Deliver to"
            options={DeliverTo}
            placeholder=""
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-item">
          <Input
            type="textarea"
            name="data[mail_transcripts]"
            id="data_mail_transcripts"
            label="Please mail my transcript(s) to this address:"
            placeholder={ADDRESS_PLACEHOLDER}
          />
        </div>
      </div>
      <p>
        Please type your name and the date below to confirm your transcript
        request, and then click submit.
      </p>
      <div className="form-row">
        <div className="form-item">
          <Input
            type="text"
            name="data[full_name]"
            id="data_full_name"
            label="Digital Signature (type full name)"
            required
          />
        </div>

        <div className="form-item">
          <Input
            type="text"
            name="data[date]"
            id="data_date"
            label="Date"
            required
            maxLength="8"
            pattern="\d{2}\/\d{2}\/\d{2}"
            placeholder="MM/DD/YY"
          />
        </div>
      </div>

      <div className="center">
        <ReCAPTCHA
          sitekey="6LfOXSUcAAAAAHgluB7FB5JrlEDkeWj9O7lNHULc"
          onChange={(value) => setRecaptcha(value)}
        />
      </div>

      <Input type="hidden" name="g-recaptcha-response" value={recaptcha} />

      <div className="center">
        <button type="submit" className="btn-primary lg" disabled={!recaptcha}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default TranscriptRequestForm;
