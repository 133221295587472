import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import AimsPrograms from "../../frontend/Forms/AimsPrograms";
import AimsProgramsInput from "../../inputs/AimsProgramsInput";
import SessionTypeInput from "../../inputs/SessionTypeInput";
import QForm from "./QForm";

const QuestionnaireFormWidget = ({
  skip_schedule,
  programs = [],
  session_types = [],
  containerSettings,
}) => {
  const safePrograms = AimsPrograms.selectPrograms(programs);

  return (
    <Container settings={containerSettings}>
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <QForm
              programs={safePrograms}
              sessionTypes={session_types}
              skipSchedule={skip_schedule}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

QuestionnaireFormWidget.title = "Questionnaire Form";
QuestionnaireFormWidget.category = "Forms";

QuestionnaireFormWidget.defaults = {
  containerSettings: {},
  skip_schedule: "no",
  session_types: SessionTypeInput.defaults,
  programs: AimsProgramsInput.defaults,
};

QuestionnaireFormWidget.adminFields = {
  skip_schedule: {
    type: "select",
    options: [
      ["yes", "Yes"],
      ["no", "No"],
    ],
  },
  session_types: { type: "custom", component: SessionTypeInput },
  programs: { type: "custom", component: AimsProgramsInput },
};

export default QuestionnaireFormWidget;
